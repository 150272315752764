// import { embedDashboard } from "amazon-quicksight-embedding-sdk";
import { MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";

const QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

const ReportsPage = () => {
  const departments = [
    "EHMG",
    "EA",
    "Administration",
    "ContinuingCare",
    "DiningServices",
    "EHWC",
    "Finance",
    "GeneralServices",
    "HomeCare",
    "HR",
    "ResidentLife",
  ];
  const departmentItems: JSX.Element[] = [];
  departments.forEach((item) => {
    departmentItems.push(
      <MenuItem value={item} key={item}>
        {item}
      </MenuItem>
    );
  });
  const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
  const [department, setDepartment] = useState("");
  const onDepartmentChange = useCallback(
    (event: any) => {
      console.log("onDepartmentChange", department, event.target.value);
      setDepartment(event.target.value);
    },
    [department]
  );

  const dashboardRef = React.createRef<HTMLDivElement>();

  const embed = () => {
    QuickSightEmbedding.embedDashboard({
      //TODO make this link dynamic based on query result
      url:
        "https://us-east-1.quicksight.aws.amazon.com/embed/22387adc4ec9419e99b9767c368e9646/dashboards/3f8f0258-5c59-4513-87cb-53cf8467d114?code=AYABeHw7wQ8NyDt2dXkwsBPcaxkAAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6MjU5NDgwNDYyMTMyOmtleS81NGYwMjdiYy03MDJhLTQxY2YtYmViNS0xNDViOTExNzFkYzMAuAECAQB4P-lAb3AsrVHPwO-wVCEmuFDTp0yz4wFfkrwutzPupewBsbHbTc8_ulznIG_fQSj36AAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDDplH0KEB_5t-l1jFwIBEIA7_wvK2jVa_odXF4xYzC9rM0dNiIgLl6c4oiWCrzfqIE3gl_qVCnyvOgfVODcM2BeGL1h-tHWlLIMF38sCAAAAAAwAABAAAAAAAAAAAAAAAAAAxw3Nq8ru3dCPhMOifueNLP____8AAAABAAAAAAAAAAAAAAABAAAA5QXeWNlUrw_rPAmo9ZXC6y6mMadbvRqGkNjeoYXyyufukmNKQodkvmaDs3ZlDhoAs1sjcE27srPrn3mU6LrYaIXMOvHlVDGBFlLCE-cYf5E2z2vm7agp8Zc9EEEV-a_vsycvcaoCnLDg0grti0rPZXBcl7En-FJbR8ozqOQY59igVeP-TudwsUETytdVVxsoVvYL418Nf0Gmnpqdugg9_-1K6AJTKQJ2ThCtOYGoeJVYv_UlHN_1-yKAn1l8kAnJv3Md9l1-VF9hsDjKvDL0nm2HNLguNZc-93CAFAsa3CEK0Q3hc0EhLPyflZv7HWieQDj15nuu&identityprovider=quicksight&isauthcode=true",
      container: dashboardRef.current,
      parameters: {
        country: "United States",
      },
      scrolling: "no",
      height: "700px",
      width: "1000px",
      locale: "en-US",
      footerPaddingEnabled: true,
      //defaultEmbeddingVisualType: TABLE // this option only applies to experience embedding and will not be used for dashboard embedding
    });
  };

  // useEffect(() => {
  //   embed();
  // });

  const getReport = () => {
    setBusyIndicator({ busy: true, message: "Saving form data..." });
    //TODO: add some kind of api call based on department to dashboard url
  };

  return (
    <React.Fragment>
      <a href="https://elmsso.erickson.com/idp/startSSO.ping?PartnerSpId=urn%3Aamazon%3Awebservices">
        Dashboard
      </a>
      {/* <h1>Reports</h1>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
        Adminsitration
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
        Continuing Care
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
        Dining Services
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
         EA
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
         EHMG
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
        EHWC
      </a>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
      Finance
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
      General Services
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
      Home Care
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
      HR
      </a>
      <br/>
      <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
      Resident Life
      </a> */}

      {/* <InputLabel id="department">Department</InputLabel>
      <Select labelId="department" className={classes.formControl} value={department} onChange={onDepartmentChange}>
        {departmentItems}
      </Select>
      <Typography variant="h5">Dashboard</Typography>
      <div ref={dashboardRef}></div>
      <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} /> */}
    </React.Fragment>
  );
};

export default ReportsPage;
