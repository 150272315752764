/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { HomecareData } from "../../Models/HomecareData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";
import YesNoRadio from "../Shared/YesNoRadio";

export default function HomecareForm() {
  const params = useParams<keyof DataEntryProps>() as DataEntryProps;
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = useState<string>();
  const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
  const [error, setError] = useState<string>(""); // Any error message from fetch / save
  const [data, setData] = useState<HomecareData>({
    infectionPreventionAntibioticStewardshipPercentageTarget: 0,
    antibioticPrescriptions: 0,
    newSurveillanceReportsCompleted: 0,

    chh60dayHospitalizationPercentageTarget: 0,
    chh60dayHospitalizationPercentage: 0,

    chhHssOprNeglectPercentageInvestigatedTarget: 0,
    chhHssOprNeglectNumberOfAllegations: 0,
    chhHssOprNeglectNumberInvestigated: 0,

    chhProcessOutcomeTimelyInitiationLess48hrTarget: 0,
    chhProcessOutcomeTimelyInitiationLess48hr: 0,

    chhMedicareStarRatingQualityTarget: 0,
    chhMedicareStarRatingQuality: 0,
    chhMedicareStarRatingPatientSurveyTarget: 0,
    chhMedicareStarRatingPatientSurvey: 0,

    chhDepartmentQapiProjectProgressGoalTarget: "",
    chhDepartmentQapiProjectProgressGoal: "",

    oprYtdAvgFuncOutcome: 0,
    oprYtdInitialScore: 0,
    oprYtdDischargeScore: 0,

    mdsDataForWorseningIndependenceTarget: 0,
    mdsDataForWorseningIndependence: 0,

    dischargeTugScoreTarget: 0,
    dischargeTugScore: 0,

    chhDepartmentQapiProjectDescription: "",
  } as HomecareData); // data from/to backend

  // Load initial data
  useEffect(() => {
    setBusyIndicator({ busy: true, message: "Loading form data..." });

    const userFetch = DataService.getUser()
      .then((user) => {
        setCurrentUser(user.getUsername());
      })
      .catch((err) => {
        setError(err.message);
      });

    const dataFetch = DataService.getFormData(params)
      .then((response) => {
        setData({ ...data, ...response });
      })
      .catch((err) => {
        setError(err.message);
      });

    // Wait for the above to complete
    Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
  }, [params, error]);

  const saveData = useCallback(
    (e: any) => {
      setBusyIndicator({ busy: true, message: "Saving form data..." });
      DataService.saveFormData(currentUser, params, data)
        .then(() => {
          navigate("/");
        })
        .catch((err) => {
          setError(err.message);
        })
        .finally(() => {
          setBusyIndicator({ busy: false });
        });
    },
    [currentUser, data]
  );

  // Callbacks for mutating state/saving
  const changeAntibioticPrescriptions = useCallback(
    (e: any) => setData({ ...data, antibioticPrescriptions: e.target.value }),
    [data]
  );
  const changeNewSurveillanceReportsCompleted = useCallback(
    (e: any) => setData({ ...data, newSurveillanceReportsCompleted: e.target.value }),
    [data]
  );

  const changeChh60dayHospitalizationPercentage = useCallback(
    (e: any) => setData({ ...data, chh60dayHospitalizationPercentage: e.target.value }),
    [data]
  );
  const changeChhHssOprNeglectNumberOfAllegations = useCallback(
    (e: any) => setData({ ...data, chhHssOprNeglectNumberOfAllegations: e.target.value }),
    [data]
  );
  const changeChhHssOprNeglectNumberInvestigated = useCallback(
    (e: any) => setData({ ...data, chhHssOprNeglectNumberInvestigated: e.target.value }),
    [data]
  );
  const changeChhProcessOutcomeTimelyInitiationLess48hr = useCallback(
    (e: any) => setData({ ...data, chhProcessOutcomeTimelyInitiationLess48hr: e.target.value }),
    [data]
  );
  const changeChhMedicareStarRatingQuality = useCallback(
    (e: any) => setData({ ...data, chhMedicareStarRatingQuality: e.target.value }),
    [data]
  );
  const changeChhMedicareStarRatingPatientSurvey = useCallback(
    (e: any) => setData({ ...data, chhMedicareStarRatingPatientSurvey: e.target.value }),
    [data]
  );
  const changeChhDepartmentQapiProjectProgressGoal = useCallback(
    (e: any) => setData({ ...data, chhDepartmentQapiProjectProgressGoal: e.target.value }),
    [data]
  );
  const changeOprYtdAvgFuncOutcome = useCallback((e: any) => setData({ ...data, oprYtdAvgFuncOutcome: e.target.value }), [
    data,
  ]);

  const changeMdsDataForWorseningIndependence = useCallback((e: any) => setData({ ...data, mdsDataForWorseningIndependence: e.target.value }), [
    data,
  ]);

  const changeDischargeTugScore = useCallback((e: any) => setData({ ...data, dischargeTugScore: e.target.value }), [
    data,
  ]);

  const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

  return (
    <React.Fragment>
      <SelectedFilters
        department={params.department}
        community={params.community}
        month={params.month}
        year={params.year}
      />

      <FieldSection>
        <legend>Infection Prevention / Antibiotic Stewardship</legend>
        <div>
          <TargetField endAdornment="%" value={data.infectionPreventionAntibioticStewardshipPercentageTarget} />
          <ActualField
            disabled
            endAdornment="%"
            error={
              Math.round((data.newSurveillanceReportsCompleted / data.antibioticPrescriptions) * 100) <
              data.infectionPreventionAntibioticStewardshipPercentageTarget
            }
            value={Math.round((data.newSurveillanceReportsCompleted / data.antibioticPrescriptions) * 100)}
          />
        </div>

        <FieldInput
          label="Number of new antibiotic prescriptions"
          value={data.antibioticPrescriptions}
          onChange={changeAntibioticPrescriptions}
        />

        <FieldInput
          label="Number of new Infection Surveillance Reports completed"
          value={data.newSurveillanceReportsCompleted}
          onChange={changeNewSurveillanceReportsCompleted}
        />
      </FieldSection>

      <FieldSection>
        <legend>CHH 60-day hospitalization percentage</legend>
        <div>
          <TargetField endAdornment="%" value={data.chh60dayHospitalizationPercentageTarget} />
          <ActualField
            disabled
            endAdornment="%"
            error={data.chh60dayHospitalizationPercentage > data.chh60dayHospitalizationPercentageTarget}
            value={data.chh60dayHospitalizationPercentage}
          />
        </div>

        <FieldInput
          label="CHH 60-day hospitalization percentage"
          endAdornment="%"
          decimal
          value={data.chh60dayHospitalizationPercentage}
          onChange={changeChh60dayHospitalizationPercentage}
        />
      </FieldSection>

      <FieldSection>
        <legend>CHH, HSS, OPR Allegations of Abuse, Neglect, Etc. - % investigated</legend>
        <div>
          <TargetField endAdornment="%" value={data.chhHssOprNeglectPercentageInvestigatedTarget} />
          <ActualField
            disabled
            endAdornment="%"
            error={
              Math.floor((data.chhHssOprNeglectNumberInvestigated / data.chhHssOprNeglectNumberOfAllegations) * 100) <
              data.chhHssOprNeglectPercentageInvestigatedTarget
            }
            value={Math.floor(
              (data.chhHssOprNeglectNumberInvestigated / data.chhHssOprNeglectNumberOfAllegations) * 100
            )}
          />
        </div>

        <FieldInput
          label="Number of Allegations"
          value={data.chhHssOprNeglectNumberOfAllegations}
          onChange={changeChhHssOprNeglectNumberOfAllegations}
        />

        <FieldInput
          label="Number Investigated"
          value={data.chhHssOprNeglectNumberInvestigated}
          onChange={changeChhHssOprNeglectNumberInvestigated}
        />
      </FieldSection>

      <FieldSection>
        <legend>CHH Process outcome: Timely Initiation of Care less than 48 hours </legend>
        <div>
          <TargetField endAdornment="%" value={data.chhProcessOutcomeTimelyInitiationLess48hrTarget} />
          <ActualField
            disabled
            endAdornment="%"
            error={
              data.chhProcessOutcomeTimelyInitiationLess48hr < data.chhProcessOutcomeTimelyInitiationLess48hrTarget
            }
            value={data.chhProcessOutcomeTimelyInitiationLess48hr}
          />
        </div>

        <FieldInput
          label="CHH Process outcome: Timely Initiation of Care"
          endAdornment="%"
          value={data.chhProcessOutcomeTimelyInitiationLess48hr}
          onChange={changeChhProcessOutcomeTimelyInitiationLess48hr}
        />
      </FieldSection>

      <FieldSection>
        <legend></legend>
        <legend>CHH Medicare Star rating - Quality</legend>
        <div>
          <TargetField value={data.chhMedicareStarRatingQualityTarget} />
          <ActualField
            decimal
            passed={data.chhMedicareStarRatingQuality >= data.chhMedicareStarRatingQualityTarget}
            value={data.chhMedicareStarRatingQuality}
            error={data.chhMedicareStarRatingQuality < data.chhMedicareStarRatingQualityTarget}
            onChange={changeChhMedicareStarRatingQuality}
          />
        </div>
        <legend>CHH Medicare Star rating - Patient Survey</legend>
        <div>
          <TargetField value={data.chhMedicareStarRatingPatientSurveyTarget} />
          <ActualField
            decimal
            value={data.chhMedicareStarRatingPatientSurvey}
            error={data.chhMedicareStarRatingPatientSurvey < data.chhMedicareStarRatingPatientSurveyTarget}
            onChange={changeChhMedicareStarRatingPatientSurvey}
          />
        </div>
      </FieldSection>

      <FieldSection>
        <legend>CHH Department QAPI Project progress to goal?</legend>
        <div>
          <TargetField value={data.chhDepartmentQapiProjectProgressGoalTarget} />

          <YesNoRadio
            label="CHH Department QAPI Project  progress to goal?"
            expectedValue="Y"
            value={data.chhDepartmentQapiProjectProgressGoal}
            onChange={changeChhDepartmentQapiProjectProgressGoal}
          />
        </div>
      </FieldSection>

      <FieldSection>
        <legend>Rehab</legend>
        <legend>MDS data for worsening of independence</legend>
        <div>
          <TargetField value={data.mdsDataForWorseningIndependenceTarget} />
          <ActualField
            value={data.mdsDataForWorseningIndependence}
            error={data.mdsDataForWorseningIndependence > data.mdsDataForWorseningIndependenceTarget}
            onChange={changeMdsDataForWorseningIndependence}
          />
        </div>
        <legend>TUG change score</legend>
        <div>
          <TargetField value={data.dischargeTugScoreTarget} />
          <ActualField
            decimal
            value={data.dischargeTugScore}
            error={data.dischargeTugScore > data.dischargeTugScoreTarget}
            onChange={changeDischargeTugScore}
          />
        </div>
      </FieldSection>

      <div style={{ marginTop: 25, marginBottom: 25 }}>
        <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
        <TextField
          style={{ width: 500 }}
          multiline
          rows="4"
          variant="outlined"
          value={data.comments}
          onChange={onCommentsChange}
        />
      </div>

      <div>
        <Button variant="contained" color="primary" onClick={saveData}>
          Submit
        </Button>
        <p style={{ color: "red" }} hidden={!error}>
          An error has occurred: {error}
        </p>
      </div>

      <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
    </React.Fragment>
  );
}
