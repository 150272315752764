/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { GeneralServicesData } from "../../Models/GeneralServicesData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";
import YesNoRadio from "../Shared/YesNoRadio";

export default function GeneralServicesForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<GeneralServicesData>({
        regWaterSamplingTestPerPolicyCompleted: "",
        numberOfPositiveTestsTarget: 0,
        numberOfPositiveTests: 0,
        numberOfFollowupsTarget: 0,
        numberOfFollowups: 0,

        annualFireDoorInspectionCompleted: "",
        egressDoorInspectionConducted: "",

        monthlyInspectionOfOutsideAirDampers: "",

        numberOfRiskonnectReports: 0,
        ilCensus: 0,
        riskonnectRepPer100residents: 0,
        averageResponseTimeTarget: 0,
        averageResponseTime: 0,
        montlyLatchCheckAuditsCompletedPerPolicy: "",
    } as GeneralServicesData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving change

    const changeRegWaterSamplingTestPerPolicyCompleted = useCallback(
        (e: any) => setData({ ...data, regWaterSamplingTestPerPolicyCompleted: e.target.value }),
        [data]
    );
    const changeNumberOfPositiveTests = useCallback(
        (e: any) => setData({ ...data, numberOfPositiveTests: e.target.value }),
        [data]
    );
    const changeNumberOfFollowups = useCallback((e: any) => setData({ ...data, numberOfFollowups: e.target.value }), [data]);

    const changeAnnualFireDoorInspectionCompleted = useCallback(
        (e: any) => setData({ ...data, annualFireDoorInspectionCompleted: e.target.value }),
        [data]
    );
    const changeEgressDoorInspectionConducted = useCallback(
        (e: any) => setData({ ...data, egressDoorInspectionConducted: e.target.value }),
        [data]
    );

    const changeMonthlyInspectionOfOutsideAirDampers = useCallback(
        (e: any) => setData({ ...data, monthlyInspectionOfOutsideAirDampers: e.target.value }),
        [data]
    );

    const changeNumberOfRiskonnectReports = useCallback(
        (e: any) => setData({ ...data, numberOfRiskonnectReports: e.target.value }),
        [data]
    );
    const changeIlCensus = useCallback((e: any) => setData({ ...data, ilCensus: e.target.value }), [data]);
    const changeAverageResponseTime = useCallback((e: any) => setData({ ...data, averageResponseTime: e.target.value }), [
        data,
    ]);
    const changeMontlyLatchCheckAuditsCompletedPerPolicy = useCallback(
        (e: any) => setData({ ...data, montlyLatchCheckAuditsCompletedPerPolicy: e.target.value }),
        [data]
    );

    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    const getRiskValue = () => {
        return Math.round(data.numberOfRiskonnectReports / (data.ilCensus / 100));
    };

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />
            <FieldSection>
                <legend>Water Safety Management/Testing</legend>
                <div>
                    <YesNoRadio
                        label="Req. water sampling and testing per policy completed"
                        expectedValue="Y"
                        value={data.regWaterSamplingTestPerPolicyCompleted}
                        onChange={changeRegWaterSamplingTestPerPolicyCompleted}
                    />
                </div>
                <div>Number of positive tests</div>
                <div>
                    <TargetField value={data.numberOfPositiveTestsTarget} />
                    <ActualField
                        passed={data.numberOfPositiveTests <= data.numberOfPositiveTestsTarget}
                        value={data.numberOfPositiveTests}
                        error={data.numberOfPositiveTests > data.numberOfPositiveTestsTarget}
                        onChange={changeNumberOfPositiveTests}
                    />
                </div>

                <div>Number of follow ups</div>
                <div>
                    <TargetField value={data.numberOfFollowupsTarget} />
                    <ActualField
                        passed={data.numberOfFollowups <= data.numberOfFollowupsTarget}
                        value={data.numberOfFollowups}
                        error={data.numberOfFollowups > data.numberOfFollowupsTarget}
                        onChange={changeNumberOfFollowups}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Regulatory Updated DOT and Life Safety</legend>

                <YesNoRadio
                    label="CC Fire Door inspection up to date"
                    expectedValue="Y"
                    value={data.annualFireDoorInspectionCompleted}
                    onChange={changeAnnualFireDoorInspectionCompleted}
                />

                <YesNoRadio
                    label="CC Egress Door Inspection and Testing conducted by SOP (Vigil System)"
                    expectedValue="Y"
                    value={data.egressDoorInspectionConducted}
                    onChange={changeEgressDoorInspectionConducted}
                />
            </FieldSection>

            <FieldSection>
                <legend>General Services Program Review</legend>

                <YesNoRadio
                    label="Monthly inspection of outside air dampers inspections"
                    expectedValue="Y"
                    value={data.monthlyInspectionOfOutsideAirDampers}
                    onChange={changeMonthlyInspectionOfOutsideAirDampers}
                />
            </FieldSection>

            <FieldSection>
                <legend>Security &amp; Emergency Services</legend>

                <FieldInput
                    label="Number of Riskonnect reports"
                    value={data.numberOfRiskonnectReports}
                    onChange={changeNumberOfRiskonnectReports}
                />

                <FieldInput label="IL census" value={data.ilCensus} onChange={changeIlCensus} />

                <FieldInput disabled label="Riskonnect reports per 100 residents" value={getRiskValue()} />

                <legend>Average response time (in mins)</legend>

                <TargetField value={data.averageResponseTimeTarget} />
                <ActualField
                    decimal
                    passed={data.averageResponseTime <= data.averageResponseTimeTarget}
                    error={data.averageResponseTime > data.averageResponseTimeTarget}
                    value={data.averageResponseTime}
                    onChange={changeAverageResponseTime}
                />

                <YesNoRadio
                    label="Monthly Latch Check Audits completed per policy"
                    expectedValue="Y"
                    value={data.montlyLatchCheckAuditsCompletedPerPolicy}
                    onChange={changeMontlyLatchCheckAuditsCompletedPerPolicy}
                />
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
