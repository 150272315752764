/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { DiningServicesData } from "../../Models/DiningServicesData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";

export default function DiningServicesForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<DiningServicesData>({
        incidentsReported: 0,

        psStrategicSpendPercentageCompletedTarget: 0,
        psStrategicSpendPercentageCompleted: 0,
        psSyscoSpendPercentageCompletedTarget: 0,
        psSyscoSpendPercentageCompleted: 0,

        comments: "",
    } as DiningServicesData); // data from / to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    /**
     * Callbacks for mutating state / saving
     */

    const changeIncidentsReported = useCallback((e: any) => setData({ ...data, incidentsReported: e.target.value }), [data]);

    // Procurement standards
    const changePsStrategicSpendPercentageCompleted = useCallback(
        (e: any) => setData({ ...data, psStrategicSpendPercentageCompleted: e.target.value }),
        [data]
    );
    const changePsSyscoSpendPercentageCompleted = useCallback(
        (e: any) => setData({ ...data, psSyscoSpendPercentageCompleted: e.target.value }),
        [data]
    );

    // Comments
    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />

            <FieldSection>
                <legend>Safety Metrics</legend>

                <div>
                    <FieldInput
                        label="Number of incidents reported"
                        value={data.incidentsReported}
                        onChange={changeIncidentsReported}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Procurement standards</legend>
                <div>Strategic spend - % completed</div>
                <div>
                    <TargetField endAdornment="%" value={data.psStrategicSpendPercentageCompletedTarget} />
                    <ActualField
                        endAdornment="%"
                        error={
                            data.psStrategicSpendPercentageCompleted < data.psStrategicSpendPercentageCompletedTarget
                        }
                        value={data.psStrategicSpendPercentageCompleted}
                        onChange={changePsStrategicSpendPercentageCompleted}
                    />
                </div>
                <div>Sysco spend - % completed</div>
                <div>
                    <TargetField endAdornment="%" value={data.psSyscoSpendPercentageCompletedTarget} />
                    <ActualField
                        endAdornment="%"
                        error={data.psSyscoSpendPercentageCompleted < data.psSyscoSpendPercentageCompletedTarget}
                        value={data.psSyscoSpendPercentageCompleted}
                        onChange={changePsSyscoSpendPercentageCompleted}
                    />
                </div>
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
