/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { FinanceData } from "../../Models/FinanceData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";
import YesNoRadio from "../Shared/YesNoRadio";

export default function FinanceForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<FinanceData>({
        promissoryNoteCompliant: "",

        dailyCensusReviewsCompletedPercentageTarget: 0,
        totalDaysInMonth: 0,
        censusReviewsCompleted: 0,

        accountsPayableTarget: 0,
        totalPotentialTimelyPCardSignoff: 0,
        totalActuaTimelyPCardSignoff: 0,
        outstandingCardholderSignoffs: 0,
        outstandingManagerApprovals: 0,

        leadershipPresence: "",
    } as FinanceData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving

    const changePromissoryNoteCompliant = useCallback(
        (e: any) => setData({ ...data, promissoryNoteCompliant: e.target.value }),
        [data]
    );

    const changeTotalDaysInMonth = useCallback((e: any) => setData({ ...data, totalDaysInMonth: e.target.value }), [data]);
    const changeCensusReviewsCompleted = useCallback(
        (e: any) => setData({ ...data, censusReviewsCompleted: e.target.value }),
        [data]
    );

    const changeTotalPotentialTimelyPCardSignoff = useCallback(
        (e: any) => setData({ ...data, totalPotentialTimelyPCardSignoff: e.target.value }),
        [data]
    );
    const changeTotalActuaTimelyPCardSignoff = useCallback(
        (e: any) => setData({ ...data, totalActuaTimelyPCardSignoff: e.target.value }),
        [data]
    );
    const changeOutstandingCardholderSignoffs = useCallback(
        (e: any) => setData({ ...data, outstandingCardholderSignoffs: e.target.value }),
        [data]
    );
    const changeOutstandingManagerApprovals = useCallback(
        (e: any) => setData({ ...data, outstandingManagerApprovals: e.target.value }),
        [data]
    );
    const changeLeadershipPresence = useCallback((e: any) => setData({ ...data, leadershipPresence: e.target.value }), [
        data,
    ]);

    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />

            <FieldSection>
                <legend>Promissory Note Review</legend>
                <div>
                    <TargetField value={"Yes"} />

                    <YesNoRadio
                        label="Are you compliant with the Promissory Note Policy?"
                        expectedValue="Y"
                        value={data.promissoryNoteCompliant}
                        onChange={changePromissoryNoteCompliant}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>% of daily census reviews completed</legend>
                <div>
                    <TargetField endAdornment="%" value={data.dailyCensusReviewsCompletedPercentageTarget} />
                    <ActualField
                        endAdornment="%"
                        disabled
                        error={
                            Math.round((data.censusReviewsCompleted / data.totalDaysInMonth) * 100) <
                            data.dailyCensusReviewsCompletedPercentageTarget
                        }
                        value={Math.round((data.censusReviewsCompleted / data.totalDaysInMonth) * 100)}
                    />
                </div>

                <FieldInput
                    label="# of census reviews completed"
                    value={data.censusReviewsCompleted}
                    onChange={changeCensusReviewsCompleted}
                />

                <FieldInput
                    label="Total days in month"
                    value={data.totalDaysInMonth}
                    onChange={changeTotalDaysInMonth}
                />
            </FieldSection>

            <FieldSection>
                <legend>Accounts payable (latest available information)</legend>
                <div>
                    <TargetField endAdornment="%" value={data.accountsPayableTarget} />
                    <ActualField
                        endAdornment="%"
                        disabled
                        error={
                            Math.round(
                                (data.totalActuaTimelyPCardSignoff / data.totalPotentialTimelyPCardSignoff) * 100
                            ) < data.accountsPayableTarget
                        }
                        value={Math.round(
                            (data.totalActuaTimelyPCardSignoff / data.totalPotentialTimelyPCardSignoff) * 100
                        )}
                    />
                </div>

                <FieldInput
                    label="Total actual Timely P-Card signoff by managers"
                    value={data.totalActuaTimelyPCardSignoff}
                    onChange={changeTotalActuaTimelyPCardSignoff}
                />

                <FieldInput
                    label="Total potential Timely P-Card signoff by managers"
                    value={data.totalPotentialTimelyPCardSignoff}
                    onChange={changeTotalPotentialTimelyPCardSignoff}
                />

                <FieldInput
                    label="Number of cardholders that did not sign off"
                    value={data.outstandingCardholderSignoffs}
                    onChange={changeOutstandingCardholderSignoffs}
                />

                <FieldInput
                    label="Number of managers who did not complete approval"
                    value={data.outstandingManagerApprovals}
                    onChange={changeOutstandingManagerApprovals}
                />
            </FieldSection>

            <FieldSection>
                <YesNoRadio
                    label="leadership presence (FD, NHA or DON) at the Triple check meeting"
                    expectedValue="Y"
                    value={data.leadershipPresence}
                    onChange={changeLeadershipPresence}
                />
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
