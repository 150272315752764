import React from "react";
import FieldSectionProps from "./FieldSectionProps";

export default function FieldSection(props: FieldSectionProps) {

    return (
        <fieldset style={{
            width: 845,
            padding: 20,
            marginBottom: 20
        }}>
            {props.children}
        </fieldset>
    );
}