import { outlinedInputClasses, styled, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import * as DataService from '../../DataService';
import ActualFieldProps from "./ActualFieldProps";
import FieldTooltip from "./FormTooltip";

export default function ActualField(props: ActualFieldProps) {

    const baseStyle = {
        labelRoot: {
            fontWeight: 'bold',
        }
    } as React.CSSProperties;

    const disabledStyle = {
        ...baseStyle,
        background: '#EADAFA', // light purple
        color: '#00C200 !important' as any, // green
        zIndex: -1
    } as React.CSSProperties;

    const successStyle = {
        ...baseStyle,
        color: "#00C200 !important" as any, // green
    } as React.CSSProperties;

    const errorStyle = {
        ...baseStyle,
        color: '#FF0000 !important' as any // red
    } as React.CSSProperties;

    const getRootStyle = (disabled: boolean, success: boolean) => {
        return disabled ? disabledStyle : (success ? successStyle : {});
    }

    const getUnderlineStyle = (disabled: boolean) => {
        return disabled ? disabledStyle : {};
    }

    const StyledTextField = useMemo(
        () =>
            styled(TextField)({
                [`& .${outlinedInputClasses.root}`]: getRootStyle(props.disabled || false, props.passed || false),
                [`& .${outlinedInputClasses.error}`]: errorStyle,
                ['& .MuiFilledInput-underline']: getUnderlineStyle(props.disabled || false)
            }),
        [props.disabled, props.passed]
    );

    const [hint, setHint] = useState<string | null>();

    DataService.getHint(props.department, props.field).then(setHint);

    const helpAdornment = () => {
        if (!hint) return null;
        return (
            <FieldTooltip text={hint} />
        );
    }

    return (
        <StyledTextField
            disabled={props.disabled}
            style={{ width: 390 }}
            label={<b style={{ fontSize: 18 }}>Actual</b>}
            InputLabelProps={{
                shrink: true,
            }}
            margin="normal"
            variant="outlined"
            InputProps={{
                endAdornment: [props.endAdornment, helpAdornment()]
            }}
            error={props.error}
            value={props.value !== null && (!isNaN(props.value) && props.value !== Infinity) ? props.value : null}
            onChange={(e) => {
                if (props.decimal) {
                    e.target.value = e.target.value.replace(/[^0-9.]+/g, '').replace(/[^0-9.]|\.(?=.*\.)/g, "");
                } else {
                    e.target.value = e.target.value ? e.target.value.replace(/\D/g, '') : e.target.value;
                }
                return props.onChange ? props.onChange(e) : undefined;
            }}
        />
    );
}