import React from "react";
import { useParams } from "react-router-dom";
import DataEntryProps from "../Models/DataEntryProps";
import AdministrationForm from "./Forms/AdministrationForm";
import ContinuiningCareForm from "./Forms/ContinuingCareForm";
import DiningServicesForm from "./Forms/DiningServicesForm";
import EAForm from "./Forms/EAForm";
import EHMGForm from "./Forms/EHMGForm";
import EHWCForm from "./Forms/EHWCForm";
import FinanceForm from "./Forms/FinanceForm";
import GeneralServicesForm from "./Forms/GeneralServicesForm";
import HomecareForm from "./Forms/HomecareForm";
import HRForm from "./Forms/HRForm";
import ResidentLifeForm from "./Forms/ResidentLifeForm";

export default function DataEntryForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    return getForm(params);
}

function getForm(params: DataEntryProps) {
    switch (params.department) {
        case "EHMG":
            return <EHMGForm />;
        case "EA":
            return <EAForm />;
        case "Administration":
            return <AdministrationForm />;
        case "Continuing Care":
            return <ContinuiningCareForm />;
        case "Dining Services":
            return <DiningServicesForm />;
        case "EHWC":
            return <EHWCForm />;
        case "Finance":
            return <FinanceForm />;
        case "General Services":
            return <GeneralServicesForm />;
        case "Home Care":
            return <HomecareForm />;
        case "HR":
            return <HRForm />;
        case "Resident Life":
            return <ResidentLifeForm />;
    }
    return <h1>Unknown form: {params.department}</h1>;
}
