import React, { useState } from "react";

interface HintJson {
    text: any;
    enabled?: boolean;
}

interface Department {
    hints: {
        [key: string]: HintJson
    };
}

interface HintFile {
    departments: {
        [key: string]: Department
    };
}

const hintJson: HintFile = {
    departments: {
        //changing from ea to ea1 so these suggestions won't show up on EA form.
        "ea1": {
            hints: {
                "Membership Enrollment": {
                    text: (<span>This is a comment.<br />This is another line</span>)
                },
                "Member follow-up service": {
                    text: "This is another hint from hints.ts"
                },
                "New Member Welcome Outreach Target": {
                    text: "This is a hint for a Target field"
                },
                "New Member Welcome Outreach Actual": {
                    text: "This is a hint for an Actual field"
                },
                "Member Service Follow Up Target": {
                    text: "This field already has an end adorment"
                }
            }
        }
    }
}

export default hintJson;