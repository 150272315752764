import React from "react";
import DataEntryProps from "../../Models/DataEntryProps";

export default function SelectedFields(props: DataEntryProps) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    const selectedFiltersStyle = {
        display: "inline-block",
        width: "30%",
        marginTop: 0
    } as React.CSSProperties;

    return (
        <div>
            <h2>Department {props.department}</h2>
            <h3 style={selectedFiltersStyle}>{`Community: ${props.community}`}</h3>
            <h3 style={selectedFiltersStyle}>{`Month: ${months[parseInt(props.month) - 1]}`}</h3>
            <h3 style={selectedFiltersStyle}>{`Year: ${props.year}`}</h3>
        </div>
    )
}