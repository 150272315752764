import { Typography } from '@mui/material';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import React, { useEffect, useState } from 'react';
import BusyIndicator from '../../Shared/BusyIndicator';
import BusyIndicatorProps from '../../Shared/BusyIndicatorProps';
import AdminService from './AdminService';
import UserList from './UserList';

export default function AdminPage() {
    const [groupList, setGroupList] = useState<CognitoIdentityServiceProvider.GroupListType>([]);
    const [userGroups, setUserGroups] = useState<{ user: CognitoIdentityServiceProvider.UserType, groups: CognitoIdentityServiceProvider.GroupListType }[]>([]);
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(''); // Any error message from fetch / save

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: 'Loading data...' });

        const groupListPromise = AdminService.listGroups()
            .then(g => {
                setGroupList(g);
            })
            .catch(err => {
                setError(err.message);
            });

        const userGroupsPromise = AdminService.getUsersWithGroups()
            .then(ug => {
                setUserGroups(ug);
            })
            .catch(err => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userGroupsPromise, groupListPromise]).finally(() => setBusyIndicator({ busy: false }));
    }, [error]);

    const handleError = (message: string) => {
        setError(message);
    };

    return (
        <React.Fragment>
            <Typography variant="h5">Admin Console</Typography>
            <p style={{ color: "red" }} hidden={!error}>An error has occurred: {error}</p>
            <UserList userGroups={userGroups} groupList={groupList} onError={handleError} />
            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
