/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { EHMGData } from "../../Models/EHMGData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";

export default function EHMGForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<EHMGData>({
        averageIlCensus: 0,
        erTransfers: 0,
        erTransfersPerThousandLimit: 0,

        calendarMonthAdmissions: 0,
        thirtyDayReadmissions: 0,
        thirtyDayReadmissionLimit: 0,

        totalScheduledVisits: 0,
        sameDayScheduledVisits: 0,
        sameDayVisitPercentageLimit: 0,

        ehmgPatients: 0,
        ehmgPatientsWithScheduledFollowup: 0,
        ehmgPatientsDesiredPercentage: 0,

        callsAfterHoursWeekendsHolidays: 0,
        tenMinuteRespondedAfwhCalls: 0,
        tenMinuteResponseDesiredPercentage: 0,

        morningHuddleParticipation: 0,
        morningHuddleDesiredPercentage: 0,

        timelyScanningCompletionParticipation: 0,
        timelyScanningCompletionDesiredPercentage: 0,

        insuranceVerificationIssues: 0,
        insuranceVerificationIssuesLimit: 0,

        monthlyCompletionFinancialDashboardPlan: "",

        ordersReferralReconciliation: 0,
        ordersReferralReconciliationDesiredPercentage: 0,

        timelyResolutionOfClaims: 0,
        timelyResolutionOfClaimsLimit: 0,

        timelyCompletionOfTelephoneAndWebEncounters: 0,
        timelyCompletionOfTelephoneAndWebEncountersLimit: 0
    } as EHMGData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving
    const changePatients = useCallback((e: any) => setData({ ...data, ehmgPatients: e.target.value }), [data]);
    const changeAverageIlCensus = useCallback((e: any) => setData({ ...data, averageIlCensus: e.target.value }), [data]);
    const changeErTransfers = useCallback((e: any) => setData({ ...data, erTransfers: e.target.value }), [data]);
    const changeCalendarMonthAdmissions = useCallback(
        (e: any) => setData({ ...data, calendarMonthAdmissions: e.target.value }),
        [data]
    );
    const changeThirtyDayReadmissions = useCallback(
        (e: any) => setData({ ...data, thirtyDayReadmissions: e.target.value }),
        [data]
    );
    const changeTotalScheduledVisits = useCallback((e: any) => setData({ ...data, totalScheduledVisits: e.target.value }), [
        data,
    ]);
    const changeSameDayScheduledVisits = useCallback(
        (e: any) => setData({ ...data, sameDayScheduledVisits: e.target.value }),
        [data]
    );
    const changeEhmgPatientsWithScheduledFollowup = useCallback(
        (e: any) => setData({ ...data, ehmgPatientsWithScheduledFollowup: e.target.value }),
        [data]
    );
    const changeCallsAfterHoursWeekendsHolidays = useCallback(
        (e: any) => setData({ ...data, callsAfterHoursWeekendsHolidays: e.target.value }),
        [data]
    );
    const changeTenMinuteRespondedAfwhCalls = useCallback(
        (e: any) => setData({ ...data, tenMinuteRespondedAfwhCalls: e.target.value }),
        [data]
    );
    const changeTimelyScanningCompletionParticipation = useCallback(
        (e: any) => setData({ ...data, timelyScanningCompletionParticipation: e.target.value }),
        [data]
    );
    const changeInsuranceVerificationIssues = useCallback(
        (e: any) => setData({ ...data, insuranceVerificationIssues: e.target.value }),
        [data]
    );
    const changeOrdersReferralReconciliation = useCallback(
        (e: any) => setData({ ...data, ordersReferralReconciliation: e.target.value }),
        [data]
    );

    const changeTimelyResolutionOfClaims = useCallback(
        (e: any) => setData({ ...data, timelyResolutionOfClaims: e.target.value }),
        [data]
    );
    const changeTimelyCompletionOfTelephoneAndWebEncounters = useCallback(
        (e: any) => setData({ ...data, timelyCompletionOfTelephoneAndWebEncounters: e.target.value }),
        [data]
    );

    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    // Account for leap years
    const daysInYear =
        (new Date(Number(params.year) + 1, 0, 1).valueOf() -
            new Date(Number(params.year), 0, 1).valueOf()) /
        (1000 * 60 * 60 * 24);
    const daysInMonth =
        (new Date(Number(params.year), Number(params.month), 1).valueOf() -
            new Date(Number(params.year), Number(params.month) - 1, 1).valueOf()) /
        (1000 * 60 * 60 * 24);

    const formulas: { [key: string]: number } = {
        erTransferRatePerThousandMonthly: Math.round(
            (data.erTransfers * 1000 * daysInYear) / (data.averageIlCensus * daysInMonth)
        ),
        eaHospitalReadmissionRate: Math.round((data.thirtyDayReadmissions / data.calendarMonthAdmissions) * 100),
    };

    const round = (value: number, precision: number) => {
        const multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />
            <FieldSection>
                <legend>ER transfer rate per thousand</legend>
                <div>
                    <TargetField value={data.erTransfersPerThousandLimit} />
                    <ActualField
                        disabled
                        error={data.erTransfersPerThousandLimit <= formulas.erTransferRatePerThousandMonthly}
                        value={formulas.erTransferRatePerThousandMonthly}
                    />
                </div>
                <FieldInput label="Average IL census" value={data.averageIlCensus} onChange={changeAverageIlCensus} />
                <FieldInput label="Number of ER transfers" value={data.erTransfers} onChange={changeErTransfers} />
            </FieldSection>

            <FieldSection>
                <legend>EA hospital readmission rate (reported quarterly)</legend>
                <div>
                    <TargetField endAdornment="%" value={data.thirtyDayReadmissionLimit} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={data.thirtyDayReadmissionLimit <= formulas.eaHospitalReadmissionRate}
                        value={formulas.eaHospitalReadmissionRate}
                    />
                </div>

                <FieldInput
                    label="All admissions to the hospital for the calendar month"
                    value={data.calendarMonthAdmissions}
                    onChange={changeCalendarMonthAdmissions}
                />

                <FieldInput
                    label="Of those admissions, those that were readmitted within 30 days"
                    value={data.thirtyDayReadmissions}
                    onChange={changeThirtyDayReadmissions}
                />
            </FieldSection>

            <FieldSection>
                <legend>Percentage of same day visits</legend>
                <div>
                    <TargetField endAdornment="%" value={data.sameDayVisitPercentageLimit} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round((data.sameDayScheduledVisits / data.totalScheduledVisits) * 100) <
                            data.sameDayVisitPercentageLimit
                        }
                        value={Math.round((data.sameDayScheduledVisits / data.totalScheduledVisits) * 100)}
                    />
                </div>

                <FieldInput
                    label="Total number of visits scheduled"
                    value={data.totalScheduledVisits}
                    onChange={changeTotalScheduledVisits}
                />

                <FieldInput
                    label="Total number of visits occuring on same day as scheduled"
                    value={data.sameDayScheduledVisits}
                    onChange={changeSameDayScheduledVisits}
                />
            </FieldSection>

            <FieldSection>
                <legend>Patients without Visits</legend>
                <div>
                    <TargetField endAdornment="%" value={data.ehmgPatientsDesiredPercentage} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            round((data.ehmgPatientsWithScheduledFollowup / data.ehmgPatients) * 100, 2) <
                            data.ehmgPatientsDesiredPercentage
                        }
                        value={round((data.ehmgPatientsWithScheduledFollowup / data.ehmgPatients) * 100, 2)}
                    />
                </div>

                <FieldInput label="Total number of patients" value={data.ehmgPatients} onChange={changePatients} />

                <FieldInput
                    label="Active patients that have had visits within the last 12 months"
                    value={data.ehmgPatientsWithScheduledFollowup}
                    onChange={changeEhmgPatientsWithScheduledFollowup}
                />
            </FieldSection>

            <FieldSection>
                <legend>% of calls (nights and weekends) in less than 10 minutes</legend>
                <div>
                    <TargetField endAdornment="%" value={data.tenMinuteResponseDesiredPercentage} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round(
                                (data.tenMinuteRespondedAfwhCalls / data.callsAfterHoursWeekendsHolidays) * 100
                            ) < data.tenMinuteResponseDesiredPercentage
                        }
                        value={Math.round(
                            (data.tenMinuteRespondedAfwhCalls / data.callsAfterHoursWeekendsHolidays) * 100
                        )}
                    />
                </div>

                <FieldInput
                    label="Number of calls after hours, weekends and holidays"
                    value={data.callsAfterHoursWeekendsHolidays}
                    onChange={changeCallsAfterHoursWeekendsHolidays}
                />

                <FieldInput
                    label="Of those calls, those that were responded to in less than 10 minutes"
                    value={data.tenMinuteRespondedAfwhCalls}
                    onChange={changeTenMinuteRespondedAfwhCalls}
                />
            </FieldSection>
            <FieldSection>
                <legend>Practice Operations</legend>

                <div>Timely completion of scanning - # of documents in folder (monthly)</div>
                <div>
                    <TargetField value={data.timelyScanningCompletionDesiredPercentage} />
                    <ActualField
                        noStyle
                        decimal
                        error={
                            data.timelyScanningCompletionParticipation > data.timelyScanningCompletionDesiredPercentage
                        }
                        value={data.timelyScanningCompletionParticipation}
                        onChange={changeTimelyScanningCompletionParticipation}
                    />
                </div>
                <div>Insurance verification issues (from RPA monthly report)</div>
                <div>
                    <TargetField value={data.insuranceVerificationIssuesLimit} />
                    <ActualField
                        noStyle
                        error={data.insuranceVerificationIssues > data.insuranceVerificationIssuesLimit}
                        value={data.insuranceVerificationIssues}
                        onChange={changeInsuranceVerificationIssues}
                    />
                </div>

                <div>% of orders and referral reconciliation</div>
                <div>
                    <TargetField endAdornment="%" value={data.ordersReferralReconciliationDesiredPercentage} />
                    <ActualField
                        noStyle
                        endAdornment="%"
                        error={data.ordersReferralReconciliation < data.ordersReferralReconciliationDesiredPercentage}
                        value={data.ordersReferralReconciliation}
                        onChange={changeOrdersReferralReconciliation}
                    />
                </div>

                <div>Timely resolution of claims</div>
                <div>
                    <TargetField value={data.timelyResolutionOfClaimsLimit} />
                    <ActualField
                        noStyle
                        error={data.timelyResolutionOfClaims > data.timelyResolutionOfClaimsLimit}
                        value={data.timelyResolutionOfClaims}
                        onChange={changeTimelyResolutionOfClaims}
                    />
                </div>

                <div>Timely completion of Telephone encounters - monthly average</div>
                <div>
                    <TargetField value={data.timelyCompletionOfTelephoneAndWebEncountersLimit} />
                    <ActualField
                        noStyle
                        decimal
                        error={data.timelyCompletionOfTelephoneAndWebEncounters > data.timelyCompletionOfTelephoneAndWebEncountersLimit}
                        value={data.timelyCompletionOfTelephoneAndWebEncounters}
                        onChange={changeTimelyCompletionOfTelephoneAndWebEncounters}
                    />
                </div>
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
