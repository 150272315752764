import { CognitoUser } from 'amazon-cognito-identity-js';
import { API, Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';
import jsonHints from "../src/hints";
import config from './aws-exports';
import DataEntryProps from "./Models/DataEntryProps";

export async function getUser(): Promise<CognitoUser> {
    return Auth.currentAuthenticatedUser();
}

function getApiFunctionApi() {
    const service = config.aws_cloud_logic_custom.find(s => s.name === 'QAPIAPI');
    if (service) {
        return service;
    }
    return config.aws_cloud_logic_custom[0];
}

export async function getFormData(dataEntryProps: DataEntryProps): Promise<DataEntryProps | any> {
    try {
        const data = await API.get(getApiFunctionApi().name,
            `/api/${dataEntryProps.department}/${dataEntryProps.community}/${dataEntryProps.year}/${dataEntryProps.month}`, 
            {
                headers: {               
                    "Origin": "https://dtst0vie2hj9n.cloudfront.net",
                },
            });
        // Set nulls to empty strings
        // @ts-ignore
        Object.keys(data).forEach((key) => (data[key] === null) && (data[key] = ''));
        // tslint:disable-next-line: no-console
        console.log('Retreived form data: ', data);
        return Promise.resolve(data);
    } catch (e) {
        return Promise.reject(e);
    }
}


export async function getEmbadedUrl(): Promise<DataEntryProps | any> {
    try {
        const data = await API.get("quickSight",   
            `/url/qs`, {});
        // Set nulls to empty strings
        // @ts-ignore
        Object.keys(data).forEach((key) => (data[key] === null) && (data[key] = ''));
        // tslint:disable-next-line: no-console
        console.log('Retreived form data: ', data);
        return Promise.resolve(data);
    } catch (e) {
        return Promise.reject(e);
    }
}

export async function saveFormData(currentUser: string | undefined, props: DataEntryProps, data: DataEntryProps) {
    // Set empty strings to null
    // @ts-ignore
    Object.keys(data).forEach((key) => (data[key] === '') && (data[key] = null));

    const bodyData = {
        ...data,
        currentUser
    }
    // tslint:disable-next-line: no-console
    console.log("Saving data...", bodyData);
    return API.put(
        getApiFunctionApi().name,
        `/api/${props.department}/${props.community}/${props.year}/${props.month}`,
        {
            body: bodyData
        });
}

export async function getHint(department: string | undefined, field: string | undefined) {
    if (department && field) {
        const d = jsonHints.departments[department];
        const hint = d.hints[field];
        return hint ? hint.text : hint;
    }
    return null;
}


export function getGroupsForLoggedInUser(loggedInUser: CognitoUser): string[] {
    return loggedInUser.getSignInUserSession()?.getAccessToken().payload["cognito:groups"];
}

export async function addUserToGroup(username: string, groupname: string) {
    const myInit = {
        body: {
            username,
            groupname
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return API.post('AdminQueries', '/addUserToGroup', myInit);
}

export async function removeUserFromGroup(username: string, groupname: string) {
    const myInit = {
        body: {
            username,
            groupname
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    return API.post('AdminQueries', '/removeUserFromGroup', myInit);
}

export async function listGroups() {
    const myInit = {
        queryStringParameters: {
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    const rest = await API.get('AdminQueries', '/listGroups', myInit) as CognitoIdentityServiceProvider.ListGroupsResponse;
    return rest.Groups as CognitoIdentityServiceProvider.GroupListType;
}

export async function getGroupsForUser(username?: string) {
    if (!username) {
        return [];
    }
    const myInit = {
        queryStringParameters: {
            'username': username
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }
    const rest = await API.get('AdminQueries', '/listGroupsForUser', myInit) as CognitoIdentityServiceProvider.ListGroupsResponse;
    return rest.Groups as CognitoIdentityServiceProvider.GroupListType;
}

export async function listUsers() {
    const myInit = {
        queryStringParameters: {
        },
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        }
    }

    const rest = await API.get('AdminQueries', '/listUsers', myInit) as CognitoIdentityServiceProvider.Types.ListUsersResponse;
    return rest.Users;
}

export async function getUsersWithGroups() {
    const users = await listUsers();
    const userGroups = [];
    if (users) {
        let user: UserType;
        for (user of users) {
            const groups = await getGroupsForUser(user.Username);
            userGroups.push({ user, groups });
        }
    }
    return userGroups;
}