/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { EHWCData } from "../../Models/EHWCData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";

export default function EHWCForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<EHWCData>({
        ifClaims: 0,
        totalClaims: 0,
        numberOfFtes: 0,
        ivClaimsFreqPer100FteCurrentQuarterTarget: 0,
        ivClaimsFreqPer100FteCurrentQuarter: 0,
        ivClaimsRollingYearAvgFreqPer100FteTarget: 0,
        ivClaimsRollingYearAvgFreqPer100Fte: 0,
        totClaimsFreqPer100FteCurrentQuarterTarget: 0,
        totClaimsFreqPer100FteCurrentQuarter: 0,
        totClaimsRollingYearAvgFreqPer100FteTarget: 0,
        totClaimsRollingYearAvgFreqPer100Fte: 0,
        claimCaseMgmtAttachSummary: 0,
        employeesWithMultipleClaims: 0,

        sprainStrainTotal: 0,
        sprainHealthServices: 0,
        sprainGlobalServices: 0,
        sprainDiningServices: 0,
        sprainResidentLife: 0,
        sprainHRAdmin: 0,

        slipFallTotal: 0,
        slipHealthServices: 0,
        slipGlobalServices: 0,
        slipDiningServices: 0,
        slipResidentLife: 0,
        slipHRAdmin: 0,

        cutPunctureScrapeTotal: 0,
        cutHealthServices: 0,
        cutGlobalServices: 0,
        cutDiningServices: 0,
        cutResidentLife: 0,
        cutHRAdmin: 0,

        strikeAgainstOrSteppingOnTotal: 0,
        strikeHealthServices: 0,
        strikeGlobalServices: 0,
        strikeDiningServices: 0,
        strikeResidentLife: 0,
        strikeHRAdmin: 0,

        caughtInUnderOrBetweenTotal: 0,
        caughtHealthServices: 0,
        caughtGlobalServices: 0,
        caughtDiningServices: 0,
        caughtResidentLife: 0,
        caughtHRAdmin: 0,

        burnTotal: 0,
        burnHealthServices: 0,
        burnGlobalServices: 0,
        burnDiningServices: 0,
        burnResidentLife: 0,
        burnHRAdmin: 0,

        struckOrInjuredByTotal: 0,
        struckHealthServices: 0,
        struckGlobalServices: 0,
        struckDiningServices: 0,
        struckResidentLife: 0,
        struckHRAdmin: 0,

        otherTotal: 0,
        otherHealthServices: 0,
        otherGlobalServices: 0,
        otherDiningServices: 0,
        otherResidentLife: 0,
        otherHRAdmin: 0,

        oshaRecordableIncidentRateTarget: 0,
        oshaRecordableCases: 0,
        numberOfEmployeeLaborHoursWorked: 0,
        ivClaimsAvgIncuredCostClaimCurrentYear: 0,
        ivClaimsAvgIncuredCostClaimPrevYear: 0,
        totalClaimsAvgIncuredCostClaimCurrentYear: 0,
        totalClaimsAvgIncuredCostClaimPrevYear: 0,
        ericksonIvClaimsAvgIncuredCostClaimCurrentYear: 0,
        ericksonTotClaimsAvgIncuredCostClaimCurrentYear: 0,
        lostTimeCaseRatePer100FteTarget: 0,
        numberOfLostTimeClaims: 0,
        fte: 0
    } as EHWCData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving
    const changeIfClaims = useCallback((e: any) => setData({ ...data, ifClaims: e.target.value }), [data]);
    const changeTotalClaims = useCallback((e: any) => setData({ ...data, totalClaims: e.target.value }), [data]);
    const changeNumberOfFtes = useCallback((e: any) => setData({ ...data, numberOfFtes: e.target.value }), [data]);
    const changeIvClaimsFreqPer100FteCurrentQuarter = useCallback(
        (e: any) => setData({ ...data, ivClaimsFreqPer100FteCurrentQuarter: e.target.value }),
        [data]
    );

    const changeIvClaimsRollingYearAvgFreqPer100Fte = useCallback(
        (e: any) => setData({ ...data, ivClaimsRollingYearAvgFreqPer100Fte: e.target.value }),
        [data]
    );
    const changeTotClaimsFreqPer100FteCurrentQuarter = useCallback(
        (e: any) => setData({ ...data, totClaimsFreqPer100FteCurrentQuarter: e.target.value }),
        [data]
    );
    const changeTotClaimsRollingYearAvgFreqPer100Fte = useCallback(
        (e: any) => setData({ ...data, totClaimsRollingYearAvgFreqPer100Fte: e.target.value }),
        [data]
    );
    const changeClaimCaseMgmtAttachSummary = useCallback(
        (e: any) => setData({ ...data, claimCaseMgmtAttachSummary: e.target.value }),
        [data]
    );
    const changeEmployeesWithMultipleClaims = useCallback(
        (e: any) => setData({ ...data, employeesWithMultipleClaims: e.target.value }),
        [data]
    );

    const changeSprainStrainTotal = useCallback((e: any) => setData({ ...data, sprainStrainTotal: e.target.value }), [data]);
    const changeSprainHealthServices = useCallback((e: any) => setData({ ...data, sprainHealthServices: e.target.value }), [
        data,
    ]);
    const changeSprainGlobalServices = useCallback((e: any) => setData({ ...data, sprainGlobalServices: e.target.value }), [
        data,
    ]);
    const changeSprainDiningServices = useCallback((e: any) => setData({ ...data, sprainDiningServices: e.target.value }), [
        data,
    ]);
    const changeSprainResidentLife = useCallback((e: any) => setData({ ...data, sprainResidentLife: e.target.value }), [
        data,
    ]);
    const changeSprainHRAdmin = useCallback((e: any) => setData({ ...data, sprainHRAdmin: e.target.value }), [
        data,
    ]);

    const changeSlipFallTotal = useCallback((e: any) => setData({ ...data, slipFallTotal: e.target.value }), [data]);
    const changeSlipHealthServices = useCallback((e: any) => setData({ ...data, slipHealthServices: e.target.value }), [
        data,
    ]);
    const changeSlipGlobalServices = useCallback((e: any) => setData({ ...data, slipGlobalServices: e.target.value }), [
        data,
    ]);
    const changeSlipDiningServices = useCallback((e: any) => setData({ ...data, slipDiningServices: e.target.value }), [
        data,
    ]);
    const changeSlipResidentLife = useCallback((e: any) => setData({ ...data, slipResidentLife: e.target.value }), [data]);
    const changeSlipHRAdmin = useCallback((e: any) => setData({ ...data, slipHRAdmin: e.target.value }), [data]);

    const changeCutPunctureScrapeTotal = useCallback(
        (e: any) => setData({ ...data, cutPunctureScrapeTotal: e.target.value }),
        [data]
    );
    const changeCutHealthServices = useCallback((e: any) => setData({ ...data, cutHealthServices: e.target.value }), [data]);
    const changeCutGlobalServices = useCallback((e: any) => setData({ ...data, cutGlobalServices: e.target.value }), [data]);
    const changeCutDiningServices = useCallback((e: any) => setData({ ...data, cutDiningServices: e.target.value }), [data]);
    const changeCutResidentLife = useCallback((e: any) => setData({ ...data, cutResidentLife: e.target.value }), [data]);
    const changeCutHRAdmin = useCallback((e: any) => setData({ ...data, cutHRAdmin: e.target.value }), [data]);

    const changeStrikeAgainstOrSteppingOnTotal = useCallback(
        (e: any) => setData({ ...data, strikeAgainstOrSteppingOnTotal: e.target.value }),
        [data]
    );
    const changeStrikeHealthServices = useCallback((e: any) => setData({ ...data, strikeHealthServices: e.target.value }), [
        data,
    ]);
    const changeStrikeGlobalServices = useCallback((e: any) => setData({ ...data, strikeGlobalServices: e.target.value }), [
        data,
    ]);
    const changeStrikeDiningServices = useCallback((e: any) => setData({ ...data, strikeDiningServices: e.target.value }), [
        data,
    ]);
    const changeStrikeResidentLife = useCallback((e: any) => setData({ ...data, strikeResidentLife: e.target.value }), [
        data,
    ]);
    const changeStrikeHRAdmin = useCallback((e: any) => setData({ ...data, strikeHRAdmin: e.target.value }), [
        data,
    ]);

    const changeCaughtInUnderOrBetweenTotal = useCallback(
        (e: any) => setData({ ...data, caughtInUnderOrBetweenTotal: e.target.value }),
        [data]
    );
    const changeCaughtHealthServices = useCallback((e: any) => setData({ ...data, caughtHealthServices: e.target.value }), [
        data,
    ]);
    const changeCaughtGlobalServices = useCallback((e: any) => setData({ ...data, caughtGlobalServices: e.target.value }), [
        data,
    ]);
    const changeCaughtDiningServices = useCallback((e: any) => setData({ ...data, caughtDiningServices: e.target.value }), [
        data,
    ]);
    const changeCaughtResidentLife = useCallback((e: any) => setData({ ...data, caughtResidentLife: e.target.value }), [
        data,
    ]);
    const changeCaughtHRAdmin = useCallback((e: any) => setData({ ...data, caughtHRAdmin: e.target.value }), [
        data,
    ]);

    const changeBurnTotal = useCallback((e: any) => setData({ ...data, burnTotal: e.target.value }), [data]);
    const changeBurnHealthServices = useCallback((e: any) => setData({ ...data, burnHealthServices: e.target.value }), [
        data,
    ]);
    const changeBurnGlobalServices = useCallback((e: any) => setData({ ...data, burnGlobalServices: e.target.value }), [
        data,
    ]);
    const changeBurnDiningServices = useCallback((e: any) => setData({ ...data, burnDiningServices: e.target.value }), [
        data,
    ]);
    const changeBurnResidentLife = useCallback((e: any) => setData({ ...data, burnResidentLife: e.target.value }), [data]);
    const changeBurnHRAdmin = useCallback((e: any) => setData({ ...data, burnHRAdmin: e.target.value }), [data]);

    const changeStruckOrInjuredByTotal = useCallback(
        (e: any) => setData({ ...data, struckOrInjuredByTotal: e.target.value }),
        [data]
    );
    const changeStruckHealthServices = useCallback((e: any) => setData({ ...data, struckHealthServices: e.target.value }), [
        data,
    ]);
    const changeStruckGlobalServices = useCallback((e: any) => setData({ ...data, struckGlobalServices: e.target.value }), [
        data,
    ]);
    const changeStruckDiningServices = useCallback((e: any) => setData({ ...data, struckDiningServices: e.target.value }), [
        data,
    ]);
    const changeStruckResidentLife = useCallback((e: any) => setData({ ...data, struckResidentLife: e.target.value }), [
        data,
    ]);
    const changeStruckHRAdmin = useCallback((e: any) => setData({ ...data, struckHRAdmin: e.target.value }), [
        data,
    ]);

    const changeOtherTotal = useCallback((e: any) => setData({ ...data, otherTotal: e.target.value }), [data]);
    const changeOtherHealthServices = useCallback((e: any) => setData({ ...data, otherHealthServices: e.target.value }), [
        data,
    ]);
    const changeOtherGlobalServices = useCallback((e: any) => setData({ ...data, otherGlobalServices: e.target.value }), [
        data,
    ]);
    const changeOtherDiningServices = useCallback((e: any) => setData({ ...data, otherDiningServices: e.target.value }), [
        data,
    ]);
    const changeOtherResidentLife = useCallback((e: any) => setData({ ...data, otherResidentLife: e.target.value }), [data]);
    const changeOtherHRAdmin = useCallback((e: any) => setData({ ...data, otherHRAdmin: e.target.value }), [data]);

    const changeOshaRecordableCases = useCallback((e: any) => setData({ ...data, oshaRecordableCases: e.target.value }), [
        data,
    ]);
    const changeNumberOfEmployeeLaborHoursWorked = useCallback(
        (e: any) => setData({ ...data, numberOfEmployeeLaborHoursWorked: e.target.value }),
        [data]
    );
    const changeIvClaimsAvgIncuredCostClaimCurrentYear = useCallback(
        (e: any) => setData({ ...data, ivClaimsAvgIncuredCostClaimCurrentYear: e.target.value }),
        [data]
    );
    const changeIvClaimsAvgIncuredCostClaimPrevYear = useCallback(
        (e: any) => setData({ ...data, ivClaimsAvgIncuredCostClaimPrevYear: e.target.value }),
        [data]
    );
    const changeTotalClaimsAvgIncuredCostClaimCurrentYear = useCallback(
        (e: any) => setData({ ...data, totalClaimsAvgIncuredCostClaimCurrentYear: e.target.value }),
        [data]
    );
    const changeTotalClaimsAvgIncuredCostClaimPrevYear = useCallback(
        (e: any) => setData({ ...data, totalClaimsAvgIncuredCostClaimPrevYear: e.target.value }),
        [data]
    );
    const changeNumberOfLostTimeClaims = useCallback(
        (e: any) => setData({ ...data, numberOfLostTimeClaims: e.target.value }),
        [data]
    );
    const changeFte = useCallback((e: any) => setData({ ...data, fte: e.target.value }), [data]);

    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />
            <FieldSection>
                <legend>Monthly claims</legend>
                <div>
                    <FieldInput label="IV claims" value={data.ifClaims} onChange={changeIfClaims} />
                    <FieldInput label="Total Claims" value={data.totalClaims} onChange={changeTotalClaims} />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Quarterly Frequency of Claims per 100 FTE</legend>
                <div>
                    <FieldInput label="Number of FTEs" value={data.numberOfFtes} onChange={changeNumberOfFtes} />
                </div>
                <legend>IV claims freq. per 100 FTE current quarter</legend>
                <div>
                    <TargetField value={data.ivClaimsFreqPer100FteCurrentQuarterTarget} />
                    <ActualField
                        decimal
                        value={data.ivClaimsFreqPer100FteCurrentQuarter}
                        error={
                            data.ivClaimsFreqPer100FteCurrentQuarter > data.ivClaimsFreqPer100FteCurrentQuarterTarget
                        }
                        onChange={changeIvClaimsFreqPer100FteCurrentQuarter}
                    />
                </div>
                <legend>IV claims rolling year avg freq. per 100 FTE through current quarter</legend>
                <div>
                    <TargetField value={data.ivClaimsRollingYearAvgFreqPer100FteTarget} />
                    <ActualField
                        decimal
                        value={data.ivClaimsRollingYearAvgFreqPer100Fte}
                        error={
                            data.ivClaimsRollingYearAvgFreqPer100Fte > data.ivClaimsRollingYearAvgFreqPer100FteTarget
                        }
                        onChange={changeIvClaimsRollingYearAvgFreqPer100Fte}
                    />
                </div>
                <legend>Tot. claims freq. per 100 FTE current quarter</legend>
                <div>
                    <TargetField value={data.totClaimsFreqPer100FteCurrentQuarterTarget} />
                    <ActualField
                        decimal
                        value={data.totClaimsFreqPer100FteCurrentQuarter}
                        error={
                            data.totClaimsFreqPer100FteCurrentQuarter > data.totClaimsFreqPer100FteCurrentQuarterTarget
                        }
                        onChange={changeTotClaimsFreqPer100FteCurrentQuarter}
                    />
                </div>
                <legend>Tot. claims rolling year avg freq. per 100 FTE through current quarter</legend>
                <div>
                    <TargetField value={data.totClaimsRollingYearAvgFreqPer100FteTarget} />
                    <ActualField
                        decimal
                        value={data.totClaimsRollingYearAvgFreqPer100Fte}
                        error={
                            data.totClaimsRollingYearAvgFreqPer100Fte > data.totClaimsRollingYearAvgFreqPer100FteTarget
                        }
                        onChange={changeTotClaimsRollingYearAvgFreqPer100Fte}
                    />
                </div>
            </FieldSection>
            <FieldSection>
                <div>
                    <FieldInput
                        label="Claim case mgmt"
                        value={data.claimCaseMgmtAttachSummary}
                        onChange={changeClaimCaseMgmtAttachSummary}
                    />
                </div>
            </FieldSection>
            <FieldSection>
                <div>
                    <FieldInput
                        label="Employees With Multiple Claims"
                        value={data.employeesWithMultipleClaims}
                        onChange={changeEmployeesWithMultipleClaims}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Departmental Trend for Claims</legend>
                <div>
                    <FieldInput
                        disabled
                        label="Sprains / Strains Total"
                        value={
                            Number(data.sprainHealthServices) +
                            Number(data.sprainGlobalServices) +
                            Number(data.sprainDiningServices) +
                            Number(data.sprainResidentLife) +
                            Number(data.sprainHRAdmin)
                        }
                        onChange={changeSprainStrainTotal}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Health Services"
                        value={data.sprainHealthServices}
                        onChange={changeSprainHealthServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="General Services"
                        value={data.sprainGlobalServices}
                        onChange={changeSprainGlobalServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Dining Services"
                        value={data.sprainDiningServices}
                        onChange={changeSprainDiningServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Resident Life"
                        value={data.sprainResidentLife}
                        onChange={changeSprainResidentLife}
                    />
                </div>
                <div>
                    <FieldInput
                        label="HR / Admin"
                        value={data.sprainHRAdmin}
                        onChange={changeSprainHRAdmin}
                    />
                </div>
                <div>
                    <FieldInput
                        disabled
                        label="Slips / Falls Total"
                        value={
                            Number(data.slipHealthServices) +
                            Number(data.slipGlobalServices) +
                            Number(data.slipDiningServices) +
                            Number(data.slipResidentLife) +
                            Number(data.slipHRAdmin)
                        }
                        onChange={changeSlipFallTotal}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Health Services"
                        value={data.slipHealthServices}
                        onChange={changeSlipHealthServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="General Services"
                        value={data.slipGlobalServices}
                        onChange={changeSlipGlobalServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Dining Services"
                        value={data.slipDiningServices}
                        onChange={changeSlipDiningServices}
                    />
                </div>
                <div>
                    <FieldInput label="Resident Life" value={data.slipResidentLife} onChange={changeSlipResidentLife} />
                </div>
                <div>
                    <FieldInput label="HR / Admin" value={data.slipHRAdmin} onChange={changeSlipHRAdmin} />
                </div>
                <div>
                    <FieldInput
                        disabled
                        label="Cuts / Punctures / Scrapes Totals"
                        value={
                            Number(data.cutHealthServices) +
                            Number(data.cutGlobalServices) +
                            Number(data.cutDiningServices) +
                            Number(data.cutResidentLife) +
                            Number(data.cutHRAdmin)
                        }
                        onChange={changeCutPunctureScrapeTotal}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Health Services"
                        value={data.cutHealthServices}
                        onChange={changeCutHealthServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="General Services"
                        value={data.cutGlobalServices}
                        onChange={changeCutGlobalServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Dining Services"
                        value={data.cutDiningServices}
                        onChange={changeCutDiningServices}
                    />
                </div>
                <div>
                    <FieldInput label="Resident Life" value={data.cutResidentLife} onChange={changeCutResidentLife} />
                </div>
                <div>
                    <FieldInput label="HR / Admin" value={data.cutHRAdmin} onChange={changeCutHRAdmin} />
                </div>
                <div>
                    <FieldInput
                        disabled
                        label="Strike Against or Stepping on Total"
                        value={
                            Number(data.strikeHealthServices) +
                            Number(data.strikeGlobalServices) +
                            Number(data.strikeDiningServices) +
                            Number(data.strikeResidentLife) +
                            Number(data.strikeHRAdmin)
                        }
                        onChange={changeStrikeAgainstOrSteppingOnTotal}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Health Services"
                        value={data.strikeHealthServices}
                        onChange={changeStrikeHealthServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="General Services"
                        value={data.strikeGlobalServices}
                        onChange={changeStrikeGlobalServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Dining Services"
                        value={data.strikeDiningServices}
                        onChange={changeStrikeDiningServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Resident Life"
                        value={data.strikeResidentLife}
                        onChange={changeStrikeResidentLife}
                    />
                </div>
                <div>
                    <FieldInput
                        label="HR / Admin"
                        value={data.strikeHRAdmin}
                        onChange={changeStrikeHRAdmin}
                    />
                </div>
                <div>
                    <FieldInput
                        disabled
                        label="Caught In, Under, or Between Total"
                        value={
                            Number(data.caughtHealthServices) +
                            Number(data.caughtGlobalServices) +
                            Number(data.caughtDiningServices) +
                            Number(data.caughtResidentLife) +
                            Number(data.caughtHRAdmin)
                        }
                        onChange={changeCaughtInUnderOrBetweenTotal}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Health Services"
                        value={data.caughtHealthServices}
                        onChange={changeCaughtHealthServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="General Services"
                        value={data.caughtGlobalServices}
                        onChange={changeCaughtGlobalServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Dining Services"
                        value={data.caughtDiningServices}
                        onChange={changeCaughtDiningServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Resident Life"
                        value={data.caughtResidentLife}
                        onChange={changeCaughtResidentLife}
                    />
                </div>
                <div>
                    <FieldInput
                        label="HR / Admin"
                        value={data.caughtHRAdmin}
                        onChange={changeCaughtHRAdmin}
                    />
                </div>
                <div>
                    <FieldInput
                        disabled
                        label="Burns Total"
                        value={
                            Number(data.burnHealthServices) +
                            Number(data.burnGlobalServices) +
                            Number(data.burnDiningServices) +
                            Number(data.burnResidentLife) +
                            Number(data.burnHRAdmin)
                        }
                        onChange={changeBurnTotal}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Health Services"
                        value={data.burnHealthServices}
                        onChange={changeBurnHealthServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="General Services"
                        value={data.burnGlobalServices}
                        onChange={changeBurnGlobalServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Dining Services"
                        value={data.burnDiningServices}
                        onChange={changeBurnDiningServices}
                    />
                </div>
                <div>
                    <FieldInput label="Resident Life" value={data.burnResidentLife} onChange={changeBurnResidentLife} />
                </div>
                <div>
                    <FieldInput label="HR / Admin" value={data.burnHRAdmin} onChange={changeBurnHRAdmin} />
                </div>
                <div>
                    <FieldInput
                        disabled
                        label="Struck or Injured Total"
                        value={
                            Number(data.struckHealthServices) +
                            Number(data.struckGlobalServices) +
                            Number(data.struckDiningServices) +
                            Number(data.struckResidentLife) +
                            Number(data.struckHRAdmin)
                        }
                        onChange={changeStruckOrInjuredByTotal}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Health Services"
                        value={data.struckHealthServices}
                        onChange={changeStruckHealthServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="General Services"
                        value={data.struckGlobalServices}
                        onChange={changeStruckGlobalServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Dining Services"
                        value={data.struckDiningServices}
                        onChange={changeStruckDiningServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Resident Life"
                        value={data.struckResidentLife}
                        onChange={changeStruckResidentLife}
                    />
                </div>
                <div>
                    <FieldInput
                        label="HR / Admin"
                        value={data.struckHRAdmin}
                        onChange={changeStruckHRAdmin}
                    />
                </div>
                <div>
                    <FieldInput
                        disabled
                        label="Other Total"
                        value={
                            Number(data.otherHealthServices) +
                            Number(data.otherGlobalServices) +
                            Number(data.otherDiningServices) +
                            Number(data.otherResidentLife) +
                            Number(data.otherHRAdmin)
                        }
                        onChange={changeOtherTotal}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Health Services"
                        value={data.otherHealthServices}
                        onChange={changeOtherHealthServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="General Services"
                        value={data.otherGlobalServices}
                        onChange={changeOtherGlobalServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Dining Services"
                        value={data.otherDiningServices}
                        onChange={changeOtherDiningServices}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Resident Life"
                        value={data.otherResidentLife}
                        onChange={changeOtherResidentLife}
                    />
                </div>
                <div>
                    <FieldInput
                        label="HR / Admin"
                        value={data.otherHRAdmin}
                        onChange={changeOtherHRAdmin}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>OSHA recordable incident rate</legend>
                <div>
                    <TargetField value={data.oshaRecordableIncidentRateTarget} />
                    <ActualField
                        disabled
                        error={
                            (data.oshaRecordableCases * 200000) / data.numberOfEmployeeLaborHoursWorked >
                            data.oshaRecordableIncidentRateTarget
                        }
                        value={(data.oshaRecordableCases * 200000) / data.numberOfEmployeeLaborHoursWorked}
                    />
                </div>
                <div>
                    <FieldInput
                        label="# OSHA recordable cases"
                        value={data.oshaRecordableCases}
                        onChange={changeOshaRecordableCases}
                    />
                </div>
                <div>
                    <FieldInput
                        label="number of employee labor hours worked"
                        value={data.numberOfEmployeeLaborHoursWorked}
                        onChange={changeNumberOfEmployeeLaborHoursWorked}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Avg - incured cost / claim - YTD current quarter</legend>
                <div>
                    <FieldInput
                        label="Incurred Value claims YTD ave incurred cost current quarter"
                        value={data.ivClaimsAvgIncuredCostClaimCurrentYear}
                        onChange={changeIvClaimsAvgIncuredCostClaimCurrentYear}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Incurred Value claims incurred cost previous year"
                        value={data.ivClaimsAvgIncuredCostClaimPrevYear}
                        onChange={changeIvClaimsAvgIncuredCostClaimPrevYear}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Total claims YTD ave incurred cost current quarter"
                        value={data.totalClaimsAvgIncuredCostClaimCurrentYear}
                        onChange={changeTotalClaimsAvgIncuredCostClaimCurrentYear}
                    />
                </div>
                <div>
                    <FieldInput
                        label="Total claims ave incurred cost previous year"
                        value={data.totalClaimsAvgIncuredCostClaimPrevYear}
                        onChange={changeTotalClaimsAvgIncuredCostClaimPrevYear}
                    />
                </div>
            </FieldSection>
            <FieldSection>
                <legend>Quarterly Lost time case rate per 100 FTE</legend>
                <div>
                    <TargetField value={data.lostTimeCaseRatePer100FteTarget} />
                    <ActualField
                        disabled
                        error={(data.numberOfLostTimeClaims / data.fte) * 100 > data.lostTimeCaseRatePer100FteTarget}
                        value={(data.numberOfLostTimeClaims / data.fte) * 100}
                    />
                </div>
                <div>
                    <FieldInput
                        label="number of lost time claims"
                        value={data.numberOfLostTimeClaims}
                        onChange={changeNumberOfLostTimeClaims}
                    />
                </div>
                <div>
                    <FieldInput label="FTE" value={data.fte} onChange={changeFte} />
                </div>
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
