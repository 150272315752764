/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:08f3ffbe-de33-4e4d-b237-d17c189d9a32",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_fmuZVnb07",
    "aws_user_pools_web_client_id": "7u3e8j72kobgc0fmj6ms8brcus",
    "oauth": {
        "domain": "qapidemo-prodqapi.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://dtst0vie2hj9n.cloudfront.net",
        "redirectSignOut": "https://dtst0vie2hj9n.cloudfront.net/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "QAPIAPI",
            "endpoint": "https://mm5z9fo020.execute-api.us-east-1.amazonaws.com/prodqapi",
            "region": "us-east-1"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://wswzc44a2j.execute-api.us-east-1.amazonaws.com/prodqapi",
            "region": "us-east-1"
        },
        {
            "name": "quickSight",
            "endpoint": "https://fnhbrrusjk.execute-api.us-east-1.amazonaws.com/prodqapi",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "erickson-qapi-20200119163825-hostingbucket-prodqapi",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://dtst0vie2hj9n.cloudfront.net"
};


export default awsmobile;
