import { HelpOutline } from "@mui/icons-material";
import { IconButton, InputAdornment, styled, Tooltip } from "@mui/material";
import FieldTooltipProps from "./FieldTooltipProps";

export default function FieldTooltip(props: FieldTooltipProps) {

    const HtmlTooltip = styled(Tooltip)(_ => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 500,
            fontSize: 0.938,
            border: '1px solid #dadde9',
        }
    }));

    return (
        <HtmlTooltip title={props.text}>
            <InputAdornment position="end">
                <IconButton>
                    <HelpOutline />
                </IconButton>
            </InputAdornment>
        </HtmlTooltip>
    );
}