import { AccountCircle, ChevronLeft, Menu } from '@mui/icons-material';
import { AppBar, Backdrop, Box, CircularProgress, Container, createTheme, CssBaseline, Divider, Drawer, IconButton, Link, List, ThemeProvider, Toolbar, Typography } from '@mui/material';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Amplify, Auth, Hub, Logger } from "aws-amplify";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import config from "./aws-exports";
import DataEntryForm from "./Components/DataEntryForm";
import AdminPage from "./Components/Forms/Admin/AdminPage";
import ReportPage from "./Components/Forms/ReportPage";
import * as DataService from "./DataService";
import Departments from "./Departments";
import { mainListItems, reportListItems } from "./Routes";

Amplify.configure(config);
Amplify.Logger.LOG_LEVEL = 'VERBOSE';

const logger = new Logger('App');

const DRAWER_WIDTH = 240;

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Erickson Living
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          backgroundColor: defaultTheme.palette.success.main
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: '2rem',
          fontWeight: 900,
          textTransform: 'uppercase',
          color: defaultTheme.palette.success.main
        },
        h2: {
          fontSize: '1.75rem',
          fontWeight: 900,
          textTransform: 'uppercase',
          color: defaultTheme.palette.primary.dark
        },
        h3: {
          fontSize: '1.5rem',
          fontWeight: 900,
          color: defaultTheme.palette.primary.dark
        },
        h4: {
          fontSize: '1.25rem',
          fontWeight: 900,
          color: defaultTheme.palette.primary.dark
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: DRAWER_WIDTH
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: defaultTheme.palette.primary.dark
        },
        root: {
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          color: '#fff',
          fontSize: '1.1rem',
          fontWeight: 600
        },
        subheader: {
          color: '#fff',
          fontSize: '.8rem',
          fontWeight: 400
        },
        root: {
          paddingTop: '16px',
          padEnd: '16px',
          padStart: '16px',
          paddingBottom: '2px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '0px'
        }
      }
    }
  }
});

const AppMain: React.FC = props => {

  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case 'customOAuthState':
    }
  });

  const [user, setUser] = useState<CognitoUser>();
  const [groups, setGroups] = useState<string[] | undefined>();
  useEffect(() => {
    (async () => {
      try {
        await new Promise(r => setTimeout(r, 3000));
        const authUser = await Auth.currentAuthenticatedUser();
        logger.debug('authUser', authUser);
        setUser(authUser);
        setGroups(DataService.getGroupsForLoggedInUser(authUser));
      } catch (err) {
        logger.error('Error', err);
        Auth.federatedSignIn({ customProvider: 'Erickson' });
      }
    })();
  }, []);

  const [open, setOpen] = React.useState(true);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const logout = useCallback(() => Auth.signOut(), []);

  const App = () => {
    return (
      <Router>
        <div style={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar
            position="absolute"
            sx={open
              ? {
                marginLeft: DRAWER_WIDTH,
                width: `calc(100% - ${DRAWER_WIDTH}px)`,
                transition: theme.transitions.create(['width', 'margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.enteringScreen,
                })
              }
              : {
                zIndex: theme.zIndex.drawer + 1,
                transition: theme.transitions.create(['width', 'margin'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.leavingScreen,
                })
              }}
          >
            <Toolbar sx={{ paddingRight: 24 }}>
              {!open &&
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                  className={clsx({ marginRight: 36 }, open && { display: 'none' })}
                >
                  <Menu />
                </IconButton>
              }
              <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
                Welcome to QAPI
              </Typography>
              <div>Logout</div>
              <IconButton onClick={logout}>
                <AccountCircle />
              </IconButton>
            </Toolbar>
          </AppBar>

          <Drawer
            variant="permanent"
            PaperProps={{
              sx: open
                ? {
                  position: 'relative',
                  whiteSpace: 'nowrap',
                  width: DRAWER_WIDTH,
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  })
                }
                :
                {
                  overflowX: 'hidden',
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                  width: theme.spacing(7),
                  [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                  }
                }
            }}
            open={open}
          >
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '0 8px',
              ...theme.mixins.toolbar
            }}>
              <IconButton onClick={handleDrawerToggle}>
                <ChevronLeft />
              </IconButton>
            </div>
            <Divider />
            <List>{mainListItems}</List>
            <List>{reportListItems}</List>
            {/* TODO: re-enalbe after dashboard demo */}
            {/* <List>
            <a href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/7a1d5bcc-84c1-4d45-a1b1-f769c25295a4">
              Report
            </a>
          </List> */}
            {/* <List hidden={!groups || groups.indexOf("admin") < 0}>{adminListItems}</List> */}
          </Drawer>
          <main
            style={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto'
            }}
          >
            <div style={{
              ...theme.mixins.toolbar
            }} />
            <Container
              maxWidth="lg"
              style={{
                paddingLeft: 0,
                paddingTop: theme.spacing(4),
                paddingBottom: theme.spacing(4),
              }}
            >
              <Routes>
                <Route path="/" element={<Departments />} />
                <Route path="/departments" element={<Departments />} />
                <Route path="/:department/:community/:year/:month" element={<DataEntryForm />} />
                <Route path="/admin" element={<AdminPage />} />
                <Route path="/report" element={<ReportPage />} />
              </Routes>
              <Box pt={4}>
                <Copyright />
              </Box>
            </Container>
          </main>
        </div >
      </Router >
    );
  }

  const LoadingScreen = () => {
    return (
      <React.Fragment>
        <img
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: -1
          }}
          src="/img/QAPILogo.png"
        />
        <Backdrop open={true} invisible={true}>
          <CircularProgress size={75} thickness={6} color="primary" />
        </Backdrop>
      </React.Fragment>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      {user
        ? <App />
        : <LoadingScreen />
      }
    </ThemeProvider>
  );
};

export default AppMain;
