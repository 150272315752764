import { Checkbox, Input, ListItemText, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import AdminService from "./AdminService";
import GroupSelectProps from "./GroupSelectProps";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 450,
        },
    },
};

export default function GroupSelect(props: GroupSelectProps) {

    const [groupNames, setGroupNames] = useState<string[]>(props.selectedGroups.map(g => g.GroupName || ''));

    const handleCheckboxChange = async (group: string, checked: boolean) => {
        const addGroup = checked;
        if (addGroup) {
            try {
                setGroupNames([...groupNames, group]);
                await AdminService.addUserToGroup(props.username, group);
            } catch (err) {
                props.onError(`Error adding user to group ${group}`);
                console.log('Error', err);
            }
        } else {
            try {
                setGroupNames(groupNames.filter(gn => gn !== group));
                await AdminService.removeUserFromGroup(props.username, group);
            } catch (err) {
                props.onError(`Error removing user from group ${group}`);
                console.log('Error', err);
            }
        }
    };

    return (
        <Select
            multiple
            value={groupNames}
            input={<Input />}
            renderValue={(selected) => (selected as string[]).join(', ')}
            MenuProps={MenuProps}>
            {props.groupList.map((group) => (
                <MenuItem key={group.GroupName} value={group.GroupName}>
                    <Checkbox onChange={(_, checked) => { return handleCheckboxChange(group.GroupName || '', checked) }} checked={groupNames.indexOf(group.GroupName || '') > -1} />
                    <ListItemText primary={group.GroupName} />
                </MenuItem>
            ))}
        </Select>
    );
}