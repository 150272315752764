/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Radio, FormControl, FormControlLabel, FormLabel, InputLabel, RadioGroup, TextField } from "@mui/material";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { HRData } from "../../Models/HRData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";

export default function HRForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<HRData>({
        vacancyRateOverallTgt: 0,
        vacancyRateOverall: 0,

        agencyUseTgt: "",
        agencyUse: "",

        vacancyRateAdministrationTgt: 0,
        vacancyRateAdministration: 0,
        vacancyRateDiningTgt: 0,
        vacancyRateDining: 0,
        vacancyRateGeneralServicesTgt: 0,
        vacancyRateGeneralServices: 0,
        vacancyRateHealthcareTgt: 0,
        vacancyRateHealthcare: 0,
        vacancyRateResidentLifeTgt: 0,
        vacancyRateResidentLife: 0,
        vacancyRateEhmgTgt: 0,
        vacancyRateEhmg: 0,

        requisitionsVacancyOpen90DaysTgt: 0,
        requisitionsVacancyOpen90Days: 0,

        hireTermRatioTgt: 0,
        numberOfHires: 0,
        numberOfTerms: 0,
        numberOfTermsLengthService6monthsTgt: 0,
        numberOfTermsLengthService6months: 0,

        developmentInternalPromotionsNonSupervisory: 0,
        developmentInternalPromotionsSupervisory: 0,
        developmentInternalDiversePromotionsAtSupervisoryOrAbove: 0,

        numberEmployeesAssignedPhishingCourse: 0,
        numberEmployeesCompleteCourseWithin7Days: 0,
        totalNumberOfEmployees: 0,
    } as HRData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving
    const changeVacancyRateOverall = useCallback((e: any) => setData({ ...data, vacancyRateOverall: e.target.value }), [
        data,
    ]);
    const changeAgencyUse = useCallback((e: any) => setData({ ...data, agencyUse: e.target.value }), [data]);
    const changeVacancyRateAdministration = useCallback(
        (e: any) => setData({ ...data, vacancyRateAdministration: e.target.value }),
        [data]
    );
    const changeVacancyRateDining = useCallback((e: any) => setData({ ...data, vacancyRateDining: e.target.value }), [data]);
    const changeVacancyRateGeneralServices = useCallback(
        (e: any) => setData({ ...data, vacancyRateGeneralServices: e.target.value }),
        [data]
    );
    const changeVacancyRateHealthcare = useCallback(
        (e: any) => setData({ ...data, vacancyRateHealthcare: e.target.value }),
        [data]
    );
    const changeVacancyRateResidentLife = useCallback(
        (e: any) => setData({ ...data, vacancyRateResidentLife: e.target.value }),
        [data]
    );
    const changeRequisitionsVacancyOpen90Days = useCallback(
        (e: any) => setData({ ...data, requisitionsVacancyOpen90Days: e.target.value }),
        [data]
    );
    const changeNumberOfHires = useCallback((e: any) => setData({ ...data, numberOfHires: e.target.value }), [data]);
    const changeNumberOfTerms = useCallback((e: any) => setData({ ...data, numberOfTerms: e.target.value }), [data]);
    const changeNumberOfTermsLengthService6months = useCallback(
        (e: any) => setData({ ...data, numberOfTermsLengthService6months: e.target.value }),
        [data]
    );

    const changeDevelopmentInternalPromotionsNonSupervisory = useCallback(
        (e: any) => setData({ ...data, developmentInternalPromotionsNonSupervisory: e.target.value }),
        [data]
    );
    const changeDevelopmentInternalPromotionsSupervisory = useCallback(
        (e: any) => setData({ ...data, developmentInternalPromotionsSupervisory: e.target.value }),
        [data]
    );
    const changeDevelopmentInternalDiversePromotionsAtSupervisoryOrAbove = useCallback(
        (e: any) => setData({ ...data, developmentInternalDiversePromotionsAtSupervisoryOrAbove: e.target.value }),
        [data]
    );

    const changeNumberEmployeesAssignedPhishingCourse = useCallback(
        (e: any) => setData({ ...data, numberEmployeesAssignedPhishingCourse: e.target.value }),
        [data]
    );
    const changeNumberEmployeesCompleteCourseWithin7Days = useCallback(
        (e: any) => setData({ ...data, numberEmployeesCompleteCourseWithin7Days: e.target.value }),
        [data]
    );
    const changeTotalNumberOfEmployees = useCallback(
        (e: any) => setData({ ...data, totalNumberOfEmployees: e.target.value }),
        [data]
    );
    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />
            <FieldSection>
                <legend>Vacancy Rate (Overall)</legend>
                <div>
                    <TargetField endAdornment="%" value={data.vacancyRateOverallTgt} />
                    <ActualField
                        noStyle
                        endAdornment="%"
                        error={data.vacancyRateOverall > data.vacancyRateOverallTgt}
                        value={data.vacancyRateOverall}
                        onChange={changeVacancyRateOverall}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Agency use</legend>
                <div>
                    <TargetField value={data.agencyUseTgt} />
                </div>

                <FormControl fullWidth margin="normal" variant="outlined">
                    <FormLabel component="legend">Actual</FormLabel>
                    <RadioGroup
                        aria-label="Agency Use"
                        name="agencyUse"
                        className={
                            data.agencyUse !== "N" ? clsx({ color: '#FF0000 !important' }) : clsx({})
                        }
                        value={data.agencyUse}
                        onChange={changeAgencyUse}
                    >
                        <FormControlLabel value="Y" control={<Radio />} label="Yes" />
                        <FormControlLabel value="N" control={<Radio />} label="No" />
                        <FormControlLabel value="NA" control={<Radio />} label="Others" />
                    </RadioGroup>
                </FormControl>
            </FieldSection>

            <FieldSection>
                <legend>Vacancy Rate (by Department)</legend>
                <h4>Administration</h4>
                <div>
                    <TargetField endAdornment="%" value={data.vacancyRateAdministrationTgt} />
                    <ActualField
                        noStyle
                        endAdornment="%"
                        decimal
                        error={data.vacancyRateAdministration > data.vacancyRateAdministrationTgt}
                        value={data.vacancyRateAdministration}
                        onChange={changeVacancyRateAdministration}
                    />
                </div>

                <h4>Dining</h4>
                <div>
                    <TargetField endAdornment="%" value={data.vacancyRateDiningTgt} />
                    <ActualField
                        noStyle
                        endAdornment="%"
                        decimal
                        error={data.vacancyRateDining > data.vacancyRateDiningTgt}
                        value={data.vacancyRateDining}
                        onChange={changeVacancyRateDining}
                    />
                </div>

                <h4>General Services</h4>
                <div>
                    <TargetField endAdornment="%" value={data.vacancyRateGeneralServicesTgt} />
                    <ActualField
                        noStyle
                        endAdornment="%"
                        decimal
                        error={data.vacancyRateGeneralServices > data.vacancyRateGeneralServicesTgt}
                        value={data.vacancyRateGeneralServices}
                        onChange={changeVacancyRateGeneralServices}
                    />
                </div>

                <h4>Healthcare</h4>
                <div>
                    <TargetField endAdornment="%" value={data.vacancyRateHealthcareTgt} />
                    <ActualField
                        noStyle
                        endAdornment="%"
                        decimal
                        error={data.vacancyRateHealthcare > data.vacancyRateHealthcareTgt}
                        value={data.vacancyRateHealthcare}
                        onChange={changeVacancyRateHealthcare}
                    />
                </div>

                <h4>Resident Life</h4>
                <div>
                    <TargetField endAdornment="%" value={data.vacancyRateResidentLifeTgt} />
                    <ActualField
                        noStyle
                        endAdornment="%"
                        decimal
                        error={data.vacancyRateResidentLife > data.vacancyRateResidentLifeTgt}
                        value={data.vacancyRateResidentLife}
                        onChange={changeVacancyRateResidentLife}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Requisitions (vacancy) open {'>'} 90 days</legend>
                <div>
                    <TargetField value={data.requisitionsVacancyOpen90DaysTgt} />
                    <ActualField
                        noStyle
                        error={data.requisitionsVacancyOpen90Days > data.requisitionsVacancyOpen90DaysTgt}
                        value={data.requisitionsVacancyOpen90Days}
                        onChange={changeRequisitionsVacancyOpen90Days}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Hire-Term Ratio</legend>
                <div>
                    <TargetField value={data.hireTermRatioTgt} />
                    <ActualField
                        noStyle
                        disabled
                        passed={data.numberOfHires / data.numberOfTerms >= data.hireTermRatioTgt}
                        error={data.numberOfHires / data.numberOfTerms < data.hireTermRatioTgt}
                        value={data.numberOfHires / data.numberOfTerms}
                    />
                </div>

                <FieldInput label="Number of hires" value={data.numberOfHires} onChange={changeNumberOfHires} />

                <FieldInput label="Number of terms" value={data.numberOfTerms} onChange={changeNumberOfTerms} />

                <h4>Number of terms with length of service less than equal to 6 months</h4>
                <div>
                    <TargetField value={data.numberOfTermsLengthService6monthsTgt} />
                    <ActualField
                        noStyle
                        error={data.numberOfTermsLengthService6months > data.numberOfTermsLengthService6monthsTgt}
                        value={data.numberOfTermsLengthService6months}
                        onChange={changeNumberOfTermsLengthService6months}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Development</legend>
                <h4>Development- Number of internal promotions - non-supervisory</h4>
                <div>
                    <ActualField
                        noStyle
                        value={data.developmentInternalPromotionsNonSupervisory}
                        onChange={changeDevelopmentInternalPromotionsNonSupervisory}
                    />
                </div>

                <h4>Development - Number of internal promotions supervisory</h4>
                <div>
                    <ActualField
                        noStyle
                        value={data.developmentInternalPromotionsSupervisory}
                        onChange={changeDevelopmentInternalPromotionsSupervisory}
                    />
                </div>

                <h4>Development - Number of Internal Diverse Promotions at Supervisory Level or Above</h4>
                <div>
                    <ActualField
                        noStyle
                        value={data.developmentInternalDiversePromotionsAtSupervisoryOrAbove}
                        onChange={changeDevelopmentInternalDiversePromotionsAtSupervisoryOrAbove}
                    />
                </div>
            </FieldSection>

            <div>
                <h4>Number of employees assigned to phishing course</h4>

                <ActualField
                    noStyle
                    value={data.numberEmployeesAssignedPhishingCourse}
                    onChange={changeNumberEmployeesAssignedPhishingCourse}
                />
            </div>

            <div>
                <h4>Number of employees who didn’t complete the course within 7 days</h4>

                <ActualField
                    noStyle
                    value={data.numberEmployeesCompleteCourseWithin7Days}
                    onChange={changeNumberEmployeesCompleteCourseWithin7Days}
                />
            </div>

            <div>
                <h4>Total number of employees</h4>

                <ActualField noStyle value={data.totalNumberOfEmployees} onChange={changeTotalNumberOfEmployees} />
            </div>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
