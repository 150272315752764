import { TextField } from "@mui/material";
import React, { useState } from "react";
import * as DataService from '../../DataService';
import FieldInputProps from "./FieldInputProps";
import FieldTooltip from "./FormTooltip";

export default function FieldInput(props: FieldInputProps) {

    const [hint, setHint] = useState<string | null>();

    DataService.getHint(props.department, props.label ? props.label : props.field).then(setHint);

    const helpAdornment = () => {
        if (!hint) return null;
        return (
            <FieldTooltip text={hint} />
        );
    }

    const getVal = (val: number | string | null) => {
        // A potentially calculated field
        if (props.disabled && !props.text) {
            return val !== null && (!isNaN(val as number) && val !== Infinity) ? val : null
        }
        return val;
    }

    return (
        <div>
            <TextField
                disabled={props.disabled}
                label={<b style={{ fontSize: 18 }}>{props.label}</b>}
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ width: 800 }}
                margin="normal"
                variant="outlined"
                InputProps={{
                    endAdornment: [props.endAdornment, helpAdornment()]
                }}
                value={getVal(props.value)}
                onChange={(e) => {
                    if (props.decimal) {
                        e.target.value = e.target.value.replace(/[^0-9.]+/g, '').replace(/[^0-9.]|\.(?=.*\.)/g, "");
                    } else if (!props.text) {
                        e.target.value = e.target.value ? e.target.value.replace(/\D/g, '') : e.target.value;
                    }
                    return props.onChange ? props.onChange(e) : undefined;
                }}
            />
        </div>
    );
}