import { outlinedInputClasses, styled, TextField } from "@mui/material";
import React, { useState } from "react";
import * as DataService from '../../DataService';
import FieldTooltip from "./FormTooltip";
import TargetFieldProps from "./TargetFieldProps";

export default function TargetField(props: TargetFieldProps) {

    const baseStyle = {
        labelRoot: {
            fontWeight: 'bold',
        }
    } as React.CSSProperties;

    const rootStyle = {
        ...baseStyle,
        background: '#FCE4D6', // beige
        zIndex: -1
    }

    const errorStyle = {
        ...baseStyle,
        background: '#EADAFA',
        color: '#FF0000 !important'
    } as React.CSSProperties;

    const underlineStyle = {
        ...baseStyle,
        background: '#FCE4D6',
        zIndex: -1
    } as React.CSSProperties;

    const [hint, setHint] = useState<string | null>();

    DataService.getHint(props.department, props.field).then(setHint);

    const StyledTextField = styled(TextField)({
        [`& .${outlinedInputClasses.root}`]: rootStyle,
        [`& .${outlinedInputClasses.error}`]: errorStyle,
        ['& .MuiFilledInput-underline']: underlineStyle
    });

    const helpAdornment = () => {
        if (!hint) return null;
        return (
            <FieldTooltip text={hint} />
        );
    }

    return (
        <StyledTextField
            disabled
            style={{ width: 390, marginRight: 20 }}
            label={<b style={{ fontSize: 18 }}>Target</b>}
            InputLabelProps={{
                shrink: true,
            }}
            margin="normal"
            variant="outlined"
            InputProps={{
                endAdornment: [props.endAdornment, helpAdornment()]
            }}
            value={props.value}
        />
    );
}