import { CognitoUser } from 'amazon-cognito-identity-js';
import { API, Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

export default class AdminService {

    private constructor() {

    }

    static async addUserToGroup(username: string, groupname: string) {
        const myInit = {
            body: {
                username,
                groupname
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.post('AdminQueries', '/addUserToGroup', myInit);
    }

    static async removeUserFromGroup(username: string, groupname: string) {
        const myInit = {
            body: {
                username,
                groupname
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.post('AdminQueries', '/removeUserFromGroup', myInit);
    }

    static async listGroups() {
        const myInit = {
            queryStringParameters: {
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const rest = await API.get('AdminQueries', '/listGroups', myInit) as CognitoIdentityServiceProvider.ListGroupsResponse;
        return rest.Groups as CognitoIdentityServiceProvider.GroupListType;
    }

    static async getGroupsForUser(username?: string) {
        if (!username) {
            return [];
        }
        const myInit = {
            queryStringParameters: {
                'username': username
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        const rest = await API.get('AdminQueries', '/listGroupsForUser', myInit) as CognitoIdentityServiceProvider.ListGroupsResponse;
        return rest.Groups as CognitoIdentityServiceProvider.GroupListType;
    }

    static async listUsers() {
        const myInit = {
            queryStringParameters: {
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        const rest = await API.get('AdminQueries', '/listUsers', myInit) as CognitoIdentityServiceProvider.Types.ListUsersResponse;
        return rest.Users;
    }

    static async getUsersWithGroups() {
        const users = await AdminService.listUsers();
        const userGroups = [];
        if (users) {
            let user: UserType;
            for (user of users) {
                const groups = await AdminService.getGroupsForUser(user.Username);
                userGroups.push({ user, groups });
            }
        }
        return userGroups;
    }

}
