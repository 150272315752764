/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { EAData } from "../../Models/EAData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";

export default function EAForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<EAData>({
        targetEnrollmentPercentage: 0,
        membershipEnrollment: 0,
        membershipTarget: 0,
        ilLeadCardsPercentageTarget: 0,
        ilLeadCardsPercentage: 0,
        ccLeadCardsPercentageTarget: 0,
        ccLeadCardsPercentage: 0,
        followUpMemberServiceTarget: 0,
        followUpMemberService: 0,
        newMemberOutreachTarget: 0,
        totalNumberOfNewMembers: 0,
        totalNumberOfNewMembersLimit: 0,
        skilledNursingFacilityLosTarget: 0,
        skilledNursingFacilityDays: 0,
        skilledNursingFacilityAdmits: 0,
        hospitalAdmissionRatePerThousandTgt: 0,
        hospitalAdmissionRatePerThousand: 0,
    } as EAData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving
    const changeMembershipEnrollment = useCallback((e: any) => setData({ ...data, membershipEnrollment: e.target.value }), [
        data,
    ]);
    const changeMembershipTarget = useCallback((e: any) => setData({ ...data, membershipTarget: e.target.value }), [data]);

    const changeIlLeadCardsPercentage = useCallback(
        (e: any) => setData({ ...data, ilLeadCardsPercentage: e.target.value }),
        [data]
    );
    const changeCcLeadCardsPercentage = useCallback(
        (e: any) => setData({ ...data, ccLeadCardsPercentage: e.target.value }),
        [data]
    );

    const changeFollowUpMemberService = useCallback(
        (e: any) => setData({ ...data, followUpMemberService: e.target.value }),
        [data]
    );

    const changeTotalNumberOfNewMembers = useCallback(
        (e: any) => setData({ ...data, totalNumberOfNewMembers: e.target.value }),
        [data]
    );

    const changeTotalNumberOfNewMembersLimit = useCallback(
        (e: any) => setData({ ...data, totalNumberOfNewMembersLimit: e.target.value }),
        [data]
    );

    const changeSkilledNursingFacilityDays = useCallback(
        (e: any) => setData({ ...data, skilledNursingFacilityDays: e.target.value }),
        [data]
    );

    const changeSkilledNursingFacilityAdmits = useCallback(
        (e: any) => setData({ ...data, skilledNursingFacilityAdmits: e.target.value }),
        [data]
    );

    const changeHospitalAdmissionRatePerThousand = useCallback(
        (e: any) => setData({ ...data, hospitalAdmissionRatePerThousand: e.target.value }),
        [data]
    );

    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    // Account for leap years
    const daysInYear =
        (new Date(Number(params.year) + 1, 0, 1).valueOf() -
            new Date(Number(params.year), 0, 1).valueOf()) /
        (1000 * 60 * 60 * 24);
    const daysInMonth =
        (new Date(Number(params.year), Number(params.month), 1).valueOf() -
            new Date(Number(params.year), Number(params.month) - 1, 1).valueOf()) /
        (1000 * 60 * 60 * 24);

    const formulas: { [key: string]: number } = {};

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />
            <FieldSection>
                <legend>Enrollment % of target</legend>
                <div>
                    <TargetField value={data.targetEnrollmentPercentage} endAdornment="%" />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round((data.membershipEnrollment / data.membershipTarget) * 100) <
                            data.targetEnrollmentPercentage
                        }
                        value={Math.round((data.membershipEnrollment / data.membershipTarget) * 100)}
                    />
                </div>

                <FieldInput
                    label="Membership Enrollment"
                    value={data.membershipEnrollment}
                    onChange={changeMembershipEnrollment}
                />

                <FieldInput label="Membership Target" value={data.membershipTarget} onChange={changeMembershipTarget} />
            </FieldSection>

            <FieldSection>
                <legend>IL % lead cards</legend>
                <div>
                    <TargetField endAdornment="%" value={data.ilLeadCardsPercentageTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={data.ilLeadCardsPercentage < data.ilLeadCardsPercentageTarget}
                        value={data.ilLeadCardsPercentage}
                    />
                </div>

                <FieldInput
                    label="IL lead Cards % age"
                    value={data.ilLeadCardsPercentage}
                    onChange={changeIlLeadCardsPercentage}
                />
            </FieldSection>

            <FieldSection>
                <legend>CC % lead cards</legend>
                <div>
                    <TargetField endAdornment="%" value={data.ccLeadCardsPercentageTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={data.ccLeadCardsPercentage < data.ccLeadCardsPercentageTarget}
                        value={data.ccLeadCardsPercentage}
                    />
                </div>

                <FieldInput
                    label="Percentage lead cards - CC"
                    value={data.ccLeadCardsPercentage}
                    onChange={changeCcLeadCardsPercentage}
                />
            </FieldSection>

            <FieldSection>
                <legend>Member Service Follow Up</legend>
                <div>
                    <TargetField
                        field="Member Service Follow Up Target"
                        endAdornment="%"
                        value={data.followUpMemberServiceTarget}
                    />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={data.followUpMemberService < data.followUpMemberServiceTarget}
                        value={data.followUpMemberService}
                    />
                </div>

                <FieldInput
                    decimal
                    label="Member follow-up service"
                    value={data.followUpMemberService}
                    onChange={changeFollowUpMemberService}
                />
            </FieldSection>

            <FieldSection>
                <legend>Skilled Nursing Facility Length of Stay (LOS)</legend>
                <div>
                    <TargetField value={data.skilledNursingFacilityLosTarget} />
                    <ActualField
                        disabled
                        error={
                            data.skilledNursingFacilityDays / data.skilledNursingFacilityAdmits >
                            data.skilledNursingFacilityLosTarget
                        }
                        value={data.skilledNursingFacilityDays / data.skilledNursingFacilityAdmits}
                    />
                </div>

                <FieldInput
                    label="SNF days"
                    value={data.skilledNursingFacilityDays}
                    onChange={changeSkilledNursingFacilityDays}
                />

                <FieldInput
                    label="SNF admits"
                    value={data.skilledNursingFacilityAdmits}
                    onChange={changeSkilledNursingFacilityAdmits}
                />
            </FieldSection>

            <FieldSection>
                <legend>Hospital Admissions Rate Per 1,000 members</legend>
                <div>
                    <TargetField value={data.hospitalAdmissionRatePerThousandTgt} />
                    <ActualField
                        disabled
                        error={data.hospitalAdmissionRatePerThousand > data.hospitalAdmissionRatePerThousandTgt}
                        value={data.hospitalAdmissionRatePerThousand}
                    />
                </div>

                <FieldInput
                    label="Hospital admission rate per thousand"
                    value={data.hospitalAdmissionRatePerThousand}
                    onChange={changeHospitalAdmissionRatePerThousand}
                />
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
