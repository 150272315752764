/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import { AdministrationData } from "../../Models/AdministrationData";
import DataEntryProps from "../../Models/DataEntryProps";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";

export default function AdministrationForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<AdministrationData>({
        rootCauseAnalysisCompletionRateOshaTarget: 0,
        totalOshaEvents: 0,
        totalCompletedRcas: 0,

        ilResidentConcernsFollowupTarget: 0,
        totalIlResidentConcernsLogged: 0,
        totalIlResidentConcernsFollowedup: 0,
        ilTrendsByTopicOrDepartment: 0,

        ccResidentConcernsFollowupTarget: 0,
        totalCcResidentConcernsLogged: 0,
        totalCcResidentConcernsFollowedup: 0,
        ccTrendsByTopicOrDepartment: 0,
    } as AdministrationData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        const urlFetch = DataService.getEmbadedUrl()
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {

            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving

    const changeTotalOshaEvents = useCallback((e: any) => setData({ ...data, totalOshaEvents: e.target.value }), [data]);
    const changeTotalCompletedRcas = useCallback((e: any) => setData({ ...data, totalCompletedRcas: e.target.value }), [
        data,
    ]);

    const changeTotalIlResidentConcernsLogged = useCallback(
        (e: any) => setData({ ...data, totalIlResidentConcernsLogged: e.target.value }),
        [data]
    );
    const changeTotalIlResidentConcernsFollowedup = useCallback(
        (e: any) => setData({ ...data, totalIlResidentConcernsFollowedup: e.target.value }),
        [data]
    );
    const changeIlTrendsByTopicOrDepartment = useCallback(
        (e: any) => setData({ ...data, ilTrendsByTopicOrDepartment: e.target.value }),
        [data]
    );

    const changeTotalCcResidentConcernsLogged = useCallback(
        (e: any) => setData({ ...data, totalCcResidentConcernsLogged: e.target.value }),
        [data]
    );
    const changeTotalCcResidentConcernsFollowedup = useCallback(
        (e: any) => setData({ ...data, totalCcResidentConcernsFollowedup: e.target.value }),
        [data]
    );
    const changeCcTrendsByTopicOrDepartment = useCallback(
        (e: any) => setData({ ...data, ccTrendsByTopicOrDepartment: e.target.value }),
        [data]
    );

    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />

            <FieldSection>
                <legend>Root Cause Analysis Completion rate for OSHA events</legend>
                <div>
                    <TargetField endAdornment="%" value={data.rootCauseAnalysisCompletionRateOshaTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round((data.totalCompletedRcas / data.totalOshaEvents) * 100) <
                            data.rootCauseAnalysisCompletionRateOshaTarget
                        }
                        value={Math.round((data.totalCompletedRcas / data.totalOshaEvents) * 100)}
                    />
                </div>

                <FieldInput
                    label="Total number of OSHA Events"
                    value={data.totalOshaEvents}
                    onChange={changeTotalOshaEvents}
                />

                <FieldInput
                    label="Total number of completed RCAs"
                    value={data.totalCompletedRcas}
                    onChange={changeTotalCompletedRcas}
                />
            </FieldSection>

            <FieldSection>
                <legend>IL Resident Concerns Follow Up </legend>
                <div>
                    <TargetField endAdornment="%" value={data.ilResidentConcernsFollowupTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round(
                                (data.totalIlResidentConcernsFollowedup / data.totalIlResidentConcernsLogged) * 100
                            ) < data.ilResidentConcernsFollowupTarget
                        }
                        value={Math.round(
                            (data.totalIlResidentConcernsFollowedup / data.totalIlResidentConcernsLogged) * 100
                        )}
                    />
                </div>

                <FieldInput
                    label="Total number of resident concerns logged"
                    value={data.totalIlResidentConcernsLogged}
                    onChange={changeTotalIlResidentConcernsLogged}
                />

                <FieldInput
                    label="Total number of resident concerns followed up"
                    value={data.totalIlResidentConcernsFollowedup}
                    onChange={changeTotalIlResidentConcernsFollowedup}
                />

                {/* <FieldInput
                    text
                    label="Trends by topic or department"
                    value={data.ilTrendsByTopicOrDepartment}
                    onChange={changeIlTrendsByTopicOrDepartment}
                /> */}
            </FieldSection>

            <FieldSection>
                <legend>CC Resident Concerns Follow Up </legend>
                <div>
                    <TargetField endAdornment="%" value={data.ccResidentConcernsFollowupTarget} />
                    <ActualField
                        endAdornment="%"
                        disabled
                        error={
                            Math.round(
                                (data.totalCcResidentConcernsFollowedup / data.totalCcResidentConcernsLogged) * 100
                            ) < data.ccResidentConcernsFollowupTarget
                        }
                        value={Math.round(
                            (data.totalCcResidentConcernsFollowedup / data.totalCcResidentConcernsLogged) * 100
                        )}
                    />
                </div>

                <FieldInput
                    label="Total number of CC resident concerns logged"
                    value={data.totalCcResidentConcernsLogged}
                    onChange={changeTotalCcResidentConcernsLogged}
                />

                <FieldInput
                    label="Total number of CC resident concerns followed up"
                    value={data.totalCcResidentConcernsFollowedup}
                    onChange={changeTotalCcResidentConcernsFollowedup}
                />

                {/* <FieldInput
                    text
                    label="CC trends by topic or department"
                    value={data.ccTrendsByTopicOrDepartment}
                    onChange={changeCcTrendsByTopicOrDepartment}
                /> */}
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
