/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import { ContinuingCareData } from "../../Models/ContinuingCareData";
import DataEntryProps from "../../Models/DataEntryProps";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";
import YesNoRadio from "../Shared/YesNoRadio";

export default function ContinuiningCareForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<ContinuingCareData>({
        nhaInvestigatedPercentageTarget: 0,
        totalAllegations: 0,
        totalAllegationsCompletedAndReported: 0,
        totalUnsignedIncidentReports: 0,
        ccHolisticAssessmentCarePlanPercentageTarget: 0,
        ccTotalResidentsLastDay: 0,
        residentHolisticAssessmentCareplanRatio: 0,
        pcaStaffTrainedPercentageTarget: 0,
        totalNumberFfNewStaff: 0,
        newStaffTraining: 0,
        nursingStaffPercentageTarget: 0,
        totalNewNursingStaff: 0,
        newNursingStaffNursingSchedule: 0,

        connectionCallsTarget: 0,
        connectionCallsNumberEnrolled: 0,
        connectionCallsFamilyMembersContacted: 0,

        medicalStarRatingQualityTarget: 0,
        medicalStarRatingQuality: 0,
        medicalStarRatingStaffingTarget: 0,
        medicalStarRatingStaffing: 0,
        medicalStarRatingInspectionsTarget: 0,
        medicalStarRatingInspections: 0,
        medicalStarRatingFverallTarget: 0,
        medicalStarRatingFverall: 0,
        hospitalReadmissionRate: 0,
        skilledAdmissionHospital: 0,
        admissionsReadmitted30days: 0,
        infectionPreventionAntinioticStewardshipPercentage: 0,
        numberFfNewAntibioticPres: 0,
        numberFfVisaFormsCompleted: 0,
        erTransferRate: 0,
        numberFfErTransfers: 0,
        totalCensusDays: 0,
        monthlyElopementsAllServiceLinesTarget: 0,
        // monthlyElopementsAllServiceLines: 0,
        completedElopementInvestigationsTarget: 0,
        // completedElopementInvestigations: 0,
        roamAlertResponseTimeTarget: 0,
        // roamAlertResponseTime: 0,
        ccDepartmentQapiProjProgressGoalTarget: "",
        ccDepartmentQapiProjProgressGoal: "",
        ccQapiDeptProjectDesc: "",
    } as ContinuingCareData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [params, error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving

    const changeTotalAllegations = useCallback((e: any) => setData({ ...data, totalAllegations: e.target.value }), [data]);
    const changeTotalAllegationsCompletedAndReported = useCallback(
        (e: any) => setData({ ...data, totalAllegationsCompletedAndReported: e.target.value }),
        [data]
    );
    const changeTotalUnsignedIncidentReports = useCallback(
        (e: any) => setData({ ...data, totalUnsignedIncidentReports: e.target.value }),
        [data]
    );

    const changeConnectionCallsNumberEnrolled = useCallback(
        (e: any) => setData({ ...data, connectionCallsNumberEnrolled: e.target.value }),
        [data]
    );
    const changeConnectionCallsFamilyMembersContacted = useCallback(
        (e: any) => setData({ ...data, connectionCallsFamilyMembersContacted: e.target.value }),
        [data]
    );

    const changeMedicalStarRatingQuality = useCallback(
        (e: any) => setData({ ...data, medicalStarRatingQuality: e.target.value }),
        [data]
    );

    const changeMedicalStarRatingStaffing = useCallback(
        (e: any) => setData({ ...data, medicalStarRatingStaffing: e.target.value }),
        [data]
    );

    const changeMedicalStarRatingInspections = useCallback(
        (e: any) => setData({ ...data, medicalStarRatingInspections: e.target.value }),
        [data]
    );

    const changeMedicalStarRatingFverall = useCallback(
        (e: any) => setData({ ...data, medicalStarRatingFverall: e.target.value }),
        [data]
    );

    const changeSkilledAdmissionHospital = useCallback(
        (e: any) => setData({ ...data, skilledAdmissionHospital: e.target.value }),
        [data]
    );
    const changeAdmissionsReadmitted30days = useCallback(
        (e: any) => setData({ ...data, admissionsReadmitted30days: e.target.value }),
        [data]
    );

    const changeNumberFfNewAntibioticPres = useCallback(
        (e: any) => setData({ ...data, numberFfNewAntibioticPres: e.target.value }),
        [data]
    );
    const changeNumberFfVisaFormsCompleted = useCallback(
        (e: any) => setData({ ...data, numberFfVisaFormsCompleted: e.target.value }),
        [data]
    );

    const changeNumberFfErTransfers = useCallback((e: any) => setData({ ...data, numberFfErTransfers: e.target.value }), [
        data,
    ]);
    const changeTotalCensusDays = useCallback((e: any) => setData({ ...data, totalCensusDays: e.target.value }), [data]);

    const changeCcDepartmentQapiProjProgressGoal = useCallback(
        (e: any) => setData({ ...data, ccDepartmentQapiProjProgressGoal: e.target.value }),
        [data]
    );

    const changeMonthlyElopementsAllServiceLines = useCallback(
        (e: any) => setData({ ...data, monthlyElopementsAllServiceLines: e.target.value }),
        [data]
    );

    const changeCompletedElopementInvestigations = useCallback(
        (e: any) => setData({ ...data, completedElopementInvestigations: e.target.value }),
        [data]
    );

    const changeRoamAlertResponseTime = useCallback(
        (e: any) => setData({ ...data, roamAlertResponseTime: e.target.value }),
        [data]
    );

    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    const erTransferRatePerThousandMonthly = (erTransfers: number, census: number) => {
        // Account for leap years
        const daysInYear =
            (new Date(Number(params.year) + 1, 0, 1).valueOf() -
                new Date(Number(params.year), 0, 1).valueOf()) /
            (1000 * 60 * 60 * 24);
        const daysInMonth =
            (new Date(Number(params.year), Number(params.month), 1).valueOf() -
                new Date(Number(params.year), Number(params.month) - 1, 1).valueOf()) /
            (1000 * 60 * 60 * 24);

        return Math.round(
            (erTransfers * 1000 * daysInYear) / (census * daysInMonth)
        )
    }

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />

            <FieldSection>
                <legend>Incident Reports</legend>

                <FieldInput
                    label="Number of unsigned incident reports"
                    value={data.totalUnsignedIncidentReports}
                    onChange={changeTotalUnsignedIncidentReports}
                />
            </FieldSection>

            <FieldSection>
                <legend>Allegations of Abuse (NHA) - % investigated</legend>

                <div>
                    <TargetField endAdornment="%" value={data.nhaInvestigatedPercentageTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round((data.totalAllegationsCompletedAndReported / data.totalAllegations) * 100) <
                            data.nhaInvestigatedPercentageTarget
                        }
                        value={Math.round((data.totalAllegationsCompletedAndReported / data.totalAllegations) * 100)}
                    />
                </div>

                <FieldInput
                    label="Number of Abuse Allegations"
                    value={data.totalAllegations}
                    onChange={changeTotalAllegations}
                />

                <FieldInput
                    label="N. of allegations with completed investigations & reported to state per policy/regulation"
                    value={data.totalAllegationsCompletedAndReported}
                    onChange={changeTotalAllegationsCompletedAndReported}
                />
            </FieldSection>

            <FieldSection>
                <legend>Elopements</legend>

                <div># of elopements for the month all service lines</div>
                <div>
                    <TargetField value={data.monthlyElopementsAllServiceLinesTarget} />
                    <ActualField
                        error={data.monthlyElopementsAllServiceLines > data.monthlyElopementsAllServiceLinesTarget}
                        value={data.monthlyElopementsAllServiceLines}
                        onChange={changeMonthlyElopementsAllServiceLines}
                    />
                </div>

                <div>
                    <TargetField endAdornment="%" value={data.completedElopementInvestigationsTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round((data.completedElopementInvestigations / data.monthlyElopementsAllServiceLines) * 100) <
                            data.completedElopementInvestigationsTarget
                        }
                        value={Math.round((data.completedElopementInvestigations / data.monthlyElopementsAllServiceLines) * 100)}
                    />
                </div>

                <FieldInput
                    label="# of completed elopement investigations"
                    value={data.completedElopementInvestigations}
                    onChange={changeCompletedElopementInvestigations}
                />

                <div>Roam Alert Response time</div>
                <div>
                    <TargetField
                        value={data.roamAlertResponseTimeTarget}
                        endAdornment="minutes"
                    />
                    <ActualField
                        error={!(data.roamAlertResponseTime <= data.roamAlertResponseTimeTarget)}
                        value={data.roamAlertResponseTime}
                        onChange={changeRoamAlertResponseTime}
                        endAdornment="minutes"
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>CC Enterprise Initiatives (NHA)</legend>

                <div>CC Connection Calls Completion</div>
                <div>
                    <TargetField endAdornment="%" value={data.connectionCallsTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round((data.connectionCallsFamilyMembersContacted / data.connectionCallsNumberEnrolled) * 100) <
                            data.connectionCallsTarget
                        }
                        value={Math.round((data.connectionCallsFamilyMembersContacted / data.connectionCallsNumberEnrolled) * 100)}
                    />
                </div>

                <FieldInput
                    label="Total number enrolled"
                    value={data.connectionCallsNumberEnrolled}
                    onChange={changeConnectionCallsNumberEnrolled}
                />

                <FieldInput
                    label="Family members contacted"
                    value={data.connectionCallsFamilyMembersContacted}
                    onChange={changeConnectionCallsFamilyMembersContacted}
                />
            </FieldSection>

            <FieldSection>
                <legend>Medicare Star Ratings (SN/LTC- NHA)</legend>
                <div>Quality</div>
                <div>
                    <TargetField value={data.medicalStarRatingQualityTarget} />
                    <ActualField
                        error={data.medicalStarRatingQuality < data.medicalStarRatingQualityTarget}
                        value={data.medicalStarRatingQuality}
                        onChange={changeMedicalStarRatingQuality}
                    />
                </div>

                <div>Staffing</div>
                <div>
                    <TargetField value={data.medicalStarRatingStaffingTarget} />
                    <ActualField
                        error={data.medicalStarRatingStaffing < data.medicalStarRatingStaffingTarget}
                        value={data.medicalStarRatingStaffing}
                        onChange={changeMedicalStarRatingStaffing}
                    />
                </div>

                <div>Inspections</div>
                <div>
                    <TargetField value={data.medicalStarRatingInspectionsTarget} />
                    <ActualField
                        error={data.medicalStarRatingInspections < data.medicalStarRatingInspectionsTarget}
                        value={data.medicalStarRatingInspections}
                        onChange={changeMedicalStarRatingInspections}
                    />
                </div>

                <div>Overall</div>
                <div>
                    <TargetField value={data.medicalStarRatingFverallTarget} />
                    <ActualField
                        error={data.medicalStarRatingFverall < data.medicalStarRatingFverallTarget}
                        value={data.medicalStarRatingFverall}
                        onChange={changeMedicalStarRatingFverall}
                    />
                </div>
            </FieldSection>

            <FieldSection>
                <legend>Hospital readmission rate (SN/LTC - DON)</legend>
                <div>
                    <TargetField endAdornment="%" value={data.hospitalReadmissionRate} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round((data.admissionsReadmitted30days / data.skilledAdmissionHospital) * 100) >
                            data.hospitalReadmissionRate
                        }
                        value={Math.round((data.admissionsReadmitted30days / data.skilledAdmissionHospital) * 100)}
                    />
                </div>

                <FieldInput
                    label="All skilled admissions from the hospital (denominator)"
                    value={data.skilledAdmissionHospital}
                    onChange={changeSkilledAdmissionHospital}
                />

                <FieldInput
                    label="Admissions readmitted within 30 days (numerator)"
                    value={data.admissionsReadmitted30days}
                    onChange={changeAdmissionsReadmitted30days}
                />
            </FieldSection>

            <FieldSection>
                <legend>Infection Prevention / Antibiotic Stewardship (SN/LTC-DON)</legend>
                <div>
                    <TargetField endAdornment="%" value={data.infectionPreventionAntinioticStewardshipPercentage} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.round((data.numberFfVisaFormsCompleted / data.numberFfNewAntibioticPres) * 100) <
                            data.infectionPreventionAntinioticStewardshipPercentage
                        }
                        value={Math.round((data.numberFfVisaFormsCompleted / data.numberFfNewAntibioticPres) * 100)}
                    />
                </div>

                <FieldInput
                    label="Number of new antibiotic prescriptions"
                    value={data.numberFfNewAntibioticPres}
                    onChange={changeNumberFfNewAntibioticPres}
                />

                <FieldInput
                    label="Number of VISA forms completed"
                    value={data.numberFfVisaFormsCompleted}
                    onChange={changeNumberFfVisaFormsCompleted}
                />
            </FieldSection>

            <FieldSection>
                <legend>ER transfer rate per thousand</legend>
                <div>
                    <TargetField value={data.erTransferRate} />
                    <ActualField
                        disabled
                        error={data.erTransferRate <= Math.round((data.numberFfErTransfers * 1000) / data.totalCensusDays)}
                        value={Math.round((data.numberFfErTransfers * 1000) / data.totalCensusDays)}
                    />
                </div>

                <FieldInput
                    label="# of ER transfers"
                    value={data.numberFfErTransfers}
                    onChange={changeNumberFfErTransfers}
                />

                <FieldInput label="Average daily census" value={data.totalCensusDays} onChange={changeTotalCensusDays} />
            </FieldSection>

            {/*<FieldSection>
                <legend>ER transfer rate per thousand</legend>
                <div>
                    <TargetField value={data.erTransfersPerThousandLimit} />
                    <ActualField
                        disabled
                        error={data.erTransfersPerThousandLimit <= formulas.erTransferRatePerThousandMonthly}
                        value={formulas.erTransferRatePerThousandMonthly}
                    />
                </div>
                <FieldInput label="Average IL census" value={data.averageIlCensus} onChange={changeAverageIlCensus} />
                <FieldInput label="Number of ER transfers" value={data.erTransfers} onChange={changeErTransfers} />
            </FieldSection>*/}

            <FieldSection>
                <legend>CC Department QAPI Project progress to goal?</legend>
                <div>
                    <TargetField value={data.ccDepartmentQapiProjProgressGoalTarget} />
                </div>
                <div>
                    <YesNoRadio
                        label="CC Department QAPI Project  progress to goal?"
                        expectedValue="Y"
                        value={data.ccDepartmentQapiProjProgressGoal}
                        onChange={changeCcDepartmentQapiProjProgressGoal}
                    />
                </div>
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
