/* eslint-disable react-hooks/exhaustive-deps */
import { Button, InputLabel, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import * as DataService from "../../DataService";
import DataEntryProps from "../../Models/DataEntryProps";
import { ResidentLifeData } from "../../Models/ResidentLifeData";
import ActualField from "../Shared/ActualField";
import BusyIndicator from "../Shared/BusyIndicator";
import BusyIndicatorProps from "../Shared/BusyIndicatorProps";
import FieldInput from "../Shared/FieldInput";
import FieldSection from "../Shared/FieldSection";
import SelectedFilters from "../Shared/SelectedFilters";
import TargetField from "../Shared/TargetField";

export default function ResidentLifeForm() {
    const params = useParams<keyof DataEntryProps>() as DataEntryProps;
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<string>();
    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(""); // Any error message from fetch / save
    const [data, setData] = useState<ResidentLifeData>({
        myEricksonActiveUsersActiveYtdTarget: 0,
        activatedAccounts: 0,
        averageDailyCensusIlMonthActive: 0,

        welcomeHomeNroParticipationTarget: 0,
        newIlResidentsAttendedNroRelatedEvent: 0,
        totalNewIlResidents: 0
    } as ResidentLifeData); // data from/to backend

    // Load initial data
    useEffect(() => {
        setBusyIndicator({ busy: true, message: "Loading form data..." });

        const userFetch = DataService.getUser()
            .then((user) => {
                setCurrentUser(user.getUsername());
            })
            .catch((err) => {
                setError(err.message);
            });

        const dataFetch = DataService.getFormData(params)
            .then((response) => {
                setData({ ...data, ...response });
            })
            .catch((err) => {
                setError(err.message);
            });

        // Wait for the above to complete
        Promise.all([userFetch, dataFetch]).finally(() => setBusyIndicator({ busy: false }));
    }, [error]);

    const saveData = useCallback(
        (e: any) => {
            setBusyIndicator({ busy: true, message: "Saving form data..." });
            DataService.saveFormData(currentUser, params, data)
                .then(() => {
                    navigate("/");
                })
                .catch((err) => {
                    setError(err.message);
                })
                .finally(() => {
                    setBusyIndicator({ busy: false });
                });
        },
        [currentUser, data]
    );

    // Callbacks for mutating state/saving
    const changeActivatedAccounts = useCallback((e: any) => setData({ ...data, activatedAccounts: e.target.value }), [data]);
    const changeAverageDailyCensusIlMonthActive = useCallback(
        (e: any) => setData({ ...data, averageDailyCensusIlMonthActive: e.target.value }),
        [data]
    );

    const changeNewIlResidentsAttendedNroRelatedEvent = useCallback(
        (e: any) => setData({ ...data, newIlResidentsAttendedNroRelatedEvent: e.target.value }),
        [data]
    );
    const changeTotalNewIlResidents = useCallback((e: any) => setData({ ...data, totalNewIlResidents: e.target.value }), [
        data,
    ]);

    const onCommentsChange = useCallback((e: any) => setData({ ...data, comments: e.target.value }), [data]);

    return (
        <React.Fragment>
            <SelectedFilters
                department={params.department}
                community={params.community}
                month={params.month}
                year={params.year}
            />
            <FieldSection>
                <legend>My Erickson Active Users - % active YTD</legend>
                <div>
                    <TargetField endAdornment="%" value={data.myEricksonActiveUsersActiveYtdTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.floor((data.activatedAccounts / data.averageDailyCensusIlMonthActive) * 100) <
                            data.myEricksonActiveUsersActiveYtdTarget
                        }
                        value={Math.floor((data.activatedAccounts / data.averageDailyCensusIlMonthActive) * 100)}
                    />
                </div>

                <FieldInput
                    label="Activated Accounts"
                    value={data.activatedAccounts}
                    onChange={changeActivatedAccounts}
                />

                <FieldInput
                    label="Average Daily Census in IL (month)"
                    value={data.averageDailyCensusIlMonthActive}
                    onChange={changeAverageDailyCensusIlMonthActive}
                />
            </FieldSection>

            <FieldSection>
                <legend>Welcome Home - NRO participation </legend>
                <div>
                    <TargetField endAdornment="%" value={data.welcomeHomeNroParticipationTarget} />
                    <ActualField
                        disabled
                        endAdornment="%"
                        error={
                            Math.floor((data.newIlResidentsAttendedNroRelatedEvent / data.totalNewIlResidents) * 100) <
                            data.welcomeHomeNroParticipationTarget
                        }
                        value={Math.floor(
                            (data.newIlResidentsAttendedNroRelatedEvent / data.totalNewIlResidents) * 100
                        )}
                    />
                </div>

                <FieldInput
                    label="New IL residents who attended at least one NRO-related event within the first 90 days of move-in"
                    value={data.newIlResidentsAttendedNroRelatedEvent}
                    onChange={changeNewIlResidentsAttendedNroRelatedEvent}
                />

                <FieldInput
                    label="Total new IL Residents who have moved in within 90 days"
                    value={data.totalNewIlResidents}
                    onChange={changeTotalNewIlResidents}
                />
            </FieldSection>

            <div style={{ marginTop: 25, marginBottom: 25 }}>
                <InputLabel style={{ marginBottom: 10 }}>Comments</InputLabel>
                <TextField
                    style={{ width: 500 }}
                    multiline
                    rows="4"
                    variant="outlined"
                    value={data.comments}
                    onChange={onCommentsChange}
                />
            </div>

            <div>
                <Button variant="contained" color="primary" onClick={saveData}>
                    Submit
                </Button>
                <p style={{ color: "red" }} hidden={!error}>
                    An error has occurred: {error}
                </p>
            </div>

            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}
