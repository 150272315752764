import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import BusyIndicatorProps from "./BusyIndicatorProps";

export default function BusyIndicator(props: BusyIndicatorProps) {

    return (
        <Backdrop
            sx={{
                color: '#CFCFCF',
                zIndex: 5000
            }}
            open={props.busy}
        >
            <h2>{props.message}</h2>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}