import AssessmentIcon from '@mui/icons-material/Assessment';
import { Button, Grid, InputLabel, MenuItem, Select, SxProps, TextField, Theme } from '@mui/material';
import { Auth } from "aws-amplify";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const formControlStyle = {
    margin: 1,
    minWidth: 120,
} as SxProps<Theme>;

const Departments: React.FC = () => {
    // Provide a list of departments as well as a year/month picker
    const departments = [
        "EHMG",
        "EA",
        "Administration",
        "Continuing Care",
        "Dining Services",
        "EHWC",
        "Finance",
        "General Services",
        "Home Care",
        "HR",
        "Resident Life",
    ];
    const departmentItems: JSX.Element[] = [];
    departments.forEach((item) => {
        departmentItems.push(
            <MenuItem value={item} key={item}>
                {item}
            </MenuItem>
        );
    });
    const months = [
        {
            name: "January",
            value: 1,
        },
        {
            name: "February",
            value: 2,
        },
        {
            name: "March",
            value: 3,
        },
        {
            name: "April",
            value: 4,
        },
        {
            name: "May",
            value: 5,
        },
        {
            name: "June",
            value: 6,
        },
        {
            name: "July",
            value: 7,
        },
        {
            name: "August",
            value: 8,
        },
        {
            name: "September",
            value: 9,
        },
        {
            name: "October",
            value: 10,
        },
        {
            name: "November",
            value: 11,
        },
        {
            name: "December",
            value: 12,
        },
    ];
    const monthItems: JSX.Element[] = [];
    months.forEach((item) => {
        monthItems.push(
            <MenuItem value={item.value} key={item.value}>
                {item.name}
            </MenuItem>
        );
    });

    const [department, setDepartment] = useState("");
    const [community, setCommunity] = useState("");
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState("");
    const onDepartmentChange = useCallback(
        (event: any) => {
            console.log("onDepartmentChange", department, event.target.value);
            setDepartment(event.target.value);
        },
        [department]
    );

    const onCommunityChange = useCallback(
        (event: any) => {
            console.log("onCommunityChange", community, event.target.value);
            setCommunity(event.target.value);
        },
        [community]
    );
    const onYearChange = useCallback(
        (event: any) => {
            console.log("onYearChange", year, event.target.value);
            setYear(event.target.value);
        },
        [year]
    );
    const onMonthChange = useCallback(
        (event: any) => {
            console.log("onMonthChange", month, event.target.value);
            setMonth(event.target.value);
        },
        [month]
    );
    const navigate = useNavigate();
    const openDataEntryForm = useCallback(() => {
        navigate([department, community, year, month].join("/"));
    }, [department, community, year, month]);

    const getCommunities = async () => {
        const user = await Auth.currentAuthenticatedUser();
        const groups = (user.signInUserSession.accessToken.payload["cognito:groups"] || []) as string[];
        const isAdmin = groups.filter(group => group === "admin").length > 0;

        const communityArray = [];

        if (isAdmin) {
            communityArray.push(
                ...[
                    "ELM",
                    "ACH",
                    "APL",
                    "APR",
                    "BBV",
                    "CCI",
                    "CCV",
                    "DVF",
                    "ETH",
                    "FRV",
                    "GSV",
                    "HSD",
                    "LHN",
                    "LPH",
                    "MGC",
                    "OCV",
                    "RWV",
                    "SBV",
                    "SLN",
                    "TCK",
                    "WCD",
                    "WRC",
                ]
            );
            if (department === 'HR') {
                communityArray.push('Corp');
            }
        } else {
            communityArray.push(...Array.from(
                new Set(
                    groups
                        .filter(group => group.startsWith("community_"))
                        .flatMap(group => group.split("_").splice(1))
                        .filter(group => group !== 'Corp')
                        .sort()
                )
            ));
            if (department === 'HR') {
                communityArray.push('Corp');
            }
        }

        return communityArray.sort();
    }

    const [communityItems, setCommunityItems] = useState<JSX.Element[]>([]);
    useEffect(() => {
        const loadCommunities = async () => {
            setCommunityItems(
                (await getCommunities()).map((item) => (
                    <MenuItem value={item} key={item}>
                        {item}
                    </MenuItem>
                ))
            );
        }
        loadCommunities();
    }, [department]);

    return (
        <React.Fragment>
            <h1>Please select your department and reporting period</h1>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item xs={12} sm={6} md={3}>
                    <InputLabel id="department">Department</InputLabel>
                    <Select
                        size="small"
                        variant="standard"
                        labelId="department"
                        sx={formControlStyle}
                        value={department}
                        onChange={onDepartmentChange}
                    >
                        {departmentItems}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <InputLabel id="community">Community</InputLabel>
                    <Select
                        size="small"
                        variant="standard"
                        labelId="community"
                        disabled={!department}
                        sx={formControlStyle}
                        value={community}
                        onChange={onCommunityChange}
                    >
                        {communityItems}
                    </Select>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        size="small"
                        variant="standard"
                        id="standard-basic"
                        disabled={!community}
                        label="Year"
                        margin={"normal"}
                        sx={formControlStyle}
                        value={year}
                        onChange={onYearChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} sx={formControlStyle}>
                    <InputLabel id="month">Month</InputLabel>
                    <Select
                        size="small"
                        variant="standard"
                        labelId="month"
                        disabled={!year}
                        sx={formControlStyle}
                        value={month}
                        onChange={onMonthChange}
                    >
                        {monthItems}
                    </Select>
                </Grid>
            </Grid>
            <Grid container direction="row" justifyContent="flex-end">
                <Button
                    disabled={!month}
                    startIcon={<AssessmentIcon />}
                    variant="contained"
                    color="primary"
                    onClick={openDataEntryForm}
                >
                    Enter Data
                </Button>
            </Grid>
        </React.Fragment>
    );
};

export default Departments;
