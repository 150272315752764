import KeyboardIcon from '@mui/icons-material/Keyboard';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export const mainListItems = (
  <React.Fragment>
    <RouterLink to="/" style={{ textDecoration: "none", color: "grey" }}>
      <ListItem button>
        <ListItemIcon>
          <KeyboardIcon />
        </ListItemIcon>
        <ListItemText primary="Data Entry" />
      </ListItem>
    </RouterLink>
  </React.Fragment>
);

export const adminListItems = (
  <RouterLink to="/admin" style={{ textDecoration: "none", color: "grey" }}>
    <ListItem button>
      <ListItemIcon>
        <SupervisorAccountIcon />
      </ListItemIcon>
      <ListItemText primary="Admin" />
    </ListItem>
  </RouterLink>
);

export const reportListItems = (
  <RouterLink to="/report" style={{ textDecoration: "none", color: "grey" }}>
    <ListItem button>
      <ListItemIcon>
        <KeyboardIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItem>
  </RouterLink>
);

export const helpListItems = (
  <RouterLink to="/help" style={{ textDecoration: "none", color: "grey" }}>
    <ListItem button>
      <ListItemIcon>
        <KeyboardIcon />
      </ListItemIcon>
      <ListItemText primary="Help" />
    </ListItem>
  </RouterLink>
);
