import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import clsx from 'clsx';
import React from "react";
import YesNoRadioProps from "./YesNoRadioProps";

export default function YesNoRadio(props: YesNoRadioProps) {

    return (
        <FormControl fullWidth margin="normal" variant="outlined">
            <FormLabel component="legend">{props.label}</FormLabel>
            <RadioGroup
                className={props.expectedValue
                    ? (props.value !== props.expectedValue ?
                        clsx({ color: '#FF0000 !important' })
                        : undefined)
                    : undefined}
                value={props.value}
                onChange={props.onChange}>
                <FormControlLabel
                    label={props.yesValueLabel ? props.yesValueLabel : "Yes"}
                    control={<Radio />}
                    value={props.yesValue ? props.yesValue : "Y"}
                />
                <FormControlLabel
                    label={props.noValueLabel ? props.noValueLabel : "No"}
                    control={<Radio />}
                    value={props.noValue ? props.noValue : "N"}
                />
            </RadioGroup>
        </FormControl>
    );
}