import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { CognitoIdentityServiceProvider } from "aws-sdk";
import React from "react";
import GroupSelect from "./GroupSelect";
import UserListProps from "./UserListProps";

export default function UserList(props: UserListProps) {

    const getAttribute = (user: CognitoIdentityServiceProvider.UserType, attributeName: string) => {
        return user.Attributes?.find(a => a.Name === attributeName)?.Value;
    }

    const getSortedEnabledUserGroups = () => {
        if (props.userGroups) {
            return props.userGroups
                .filter(ug => ug.user.Enabled)
                .sort((a, b) => 0 - ((getAttribute(a.user, 'email') || '') > (getAttribute(b.user, 'email') || '') ? 1 : -1));
        }
        return [];
    }

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>User</TableCell>
                        <TableCell align="right">Groups</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {getSortedEnabledUserGroups().map(ug => (
                        <TableRow key={ug.user.Username}>
                            <TableCell component="th" scope="row">
                                {getAttribute(ug.user, 'email')}
                            </TableCell>
                            <TableCell align="right">
                                <GroupSelect onError={props.onError} username={ug.user.Username || ''} selectedGroups={ug.groups} groupList={props.groupList} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}